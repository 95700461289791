// # state
import { setDialer } from "../../features/dialer/dialer";
import {
  saveAccountDetails,
  setAllOrgsDetails,
  saveSelectedOrg,
  setSubscriptionDetails,
  setCredits,
  setSoftphone,
  saveOrgId,
  resetAccountDetails,
} from "../../features/account/account";
import {
  topNavAccountPlusIcon,
  topNavAccountSelectedAccountTickIcon,
  topNavDialPad,
  endCallIcon,
  initiateOrAcceptCallIcon,
  errorIcon,
  modalCloseIcon,
} from "../../../utility-functions/assetLoader";
import {
  capitalizeString,
  formatDate,
  formatTime,
  getOrgURLParam,
} from "../../../utility-functions";
import {
  reduxPersistSelectedVirtualNumber,
  setVirtualNumber,
} from "../../features/virtual-number/virtualNumber";

// # lib
import clevertap from "clevertap-web-sdk";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Menu, MenuItem, MenuList } from "@mui/material";
import { Box } from "@mui/system";
import libphonenumber from "google-libphonenumber";

// # assets
import topNavLogo from "../../../assets/top-nav-logo.svg";
import dialerAvatar from "../../../assets/dialer-update-call-status-user-avatar.svg";
import downArrow from "../../../assets/gusers-and-teams-down-arrow.svg";
import searchIcon from "../../../assets/SearchIcon.svg";
import dialerNoVirtualNumber from "../../../assets/dialerNoVirtualNumber.svg";
import dialerCallNotInitiated from "../../../assets/DialerCallNotInitiated.svg";
import dialerNoInternet from "../../../assets/DialerNoInternet.svg";

// # components
import AnimationPage from "../animation-page/AnimationPage";
import Button from "../../design/Button";
//import PlanModal1 from '../../UpgradePlan/PlanModal1'

// # css
import "./topNavStyles.css";
import colors from "../../design/colors";
import styles from "../../design/styles";

import { JService } from "../../api-service/ApiService";
import { pushToClevertap } from "../../../utility-functions/clevertap";
import jwt_decode from "jwt-decode";

// sip
import {
  UserAgent,
  Registerer,
  Inviter,
  SessionState,
  RegistererState,
  UserAgentState,
} from "sip.js";

//#data
import DialerErrorScreen from "./DialerErrorScreen";
import PlanModal1 from "../../UpgradePlan/PlanModal1";
import { enqueueSnackbar } from "notistack";
import {
  CustomSelect,
  CustomTooltip,
} from "../../design/components/customComponents";
import Micpermission from "./Dialer/Micpermission.js";
import CallConnecting from "./Dialer/CallConnecting.js";
import CallDetails from "./Dialer/CallDetails.js";
import { resetAuthState } from "../../features/permission/permission.js";
const CONTSTRAINTS = { audio: true, video: false };
const SESSION_STATES = [
  SessionState.Initial,
  SessionState.Establishing,
  SessionState.Established,
  SessionState.Terminating,
  SessionState.Terminated,
];

export let sipUserAgent = null;

export default function TopNav() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const API_BASE_URL = process.env.REACT_APP_BETA;

  const globalDialerState = useSelector((state) => state?.globalDialer?.value);
  const accountDetails = useSelector((state) => state?.account?.value);
  const reduxSelectedOrg = useSelector(
    (state) => state?.account?.value?.selectedOrg
  );
  const reduxSubscription = useSelector(
    (state) => state?.account?.value?.subscription
  );
  const reduxCredits = useSelector((state) => state?.account?.value?.credits);
  const virtualNumberState = useSelector(
    (state) => state?.virtualNumber?.value
  );
  const browserCalls = useSelector((state) => state?.account?.value?.softphone);
  const { canManageBillingSubscription, canMakeCalls } = useSelector(
    (state) => state?.auth?.permissions
  );

  const [isAccountInactive, setIsAccountInactive] = useState(false);
  const [isAccountPreActivation, setIsAccountPreActivation] = useState(false);
  const [callingValue, setCallingValue] = useState("");

  const [isProfileDropdownVisible, setIsProfileDropdownVisible] =
    useState(false);
  const [profileDropdownAnchorEl, setProfileDropdownAnchorEl] = useState(null);

  const [isCallingDialerDropdownVisible, setIsCallingDialerDropdownVisible] =
    useState(false);
  const [callingDialerDropdownAnchorEl, setCallingDialerDropdownAnchorEl] =
    useState(null);

  const [isCCDropdownVisible, setIsCCDropdownVisible] = useState(false);
  const [searchedCountryCode, setSearchedCountryCode] = useState("");
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [ccData, setCCData] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState(
    localStorage.getItem("frejun-selected-country-code") || "+91"
  );
  const [
    currentScreenInCallingDialerFlow,
    setCurrentScreenInCallingDialerFlow,
  ] = useState();

  const [outboundVirtualNumbers, setOutboundVirtualNumbers] = useState([]);
  const [selectedOutboundNumber, setSelectedOutboundNumber] = useState("");
  const [callBtnDisabled, setCallBtnDisabled] = useState(true);
  //const [clearTimer, setClearTimer] = useState(false);  //uncomment if timer is enabled

  const [dialerCandidateDetails, setDialerCandidateDetails] = useState({
    name: "",
    number: "",
    id: "",
  });
  const [planModalVisible, setPlanModalVisible] = useState(false);
  const [trialEndInfo, setTrialEndInfo] = useState({
    trial: false,
    message: "",
  });
  const [trialDaysLeftInfo, setTrialDaysLeftInfo] = useState({
    trial: false,
    message: "",
  });
  const [subsEndInfo, setSubsEndInfo] = useState({
    visible: false,
    message: "",
    btnText: "",
    btnClick: () => null,
  });
  const [licenseDeletionInfo, setLicenseDeletionInfo] = useState(false);

  const [orgDetails, setOrgDetails] = useState([]);
  const [payingInvoice, setPayingInvoice] = useState(false);

  const [allOrgs, setAllOrgs] = useState(null);

  const [selectedOrg, setSelectedOrg] = useState("");
  const [userStatus, setUserStatus] = useState(
    accountDetails?.data?.profile?.status
  );
  const [sip, setSip] = useState({
    sipToken: "",
    sipUsername: "",
    isOpen: false,
    setup: "idle",

    // to make calls
    userAgent: null,
    registerer: null,
    isRegistered: false,
    isCall: false,
  });
  const [isMute, setIsMute] = useState(false);
  const [isHold, setIsHold] = useState(false);
  const [micPermissions, setMicPermissions] = useState(null);
  const [isIncomingCall, setIsIncomingCall] = useState(false);
  const [isOutgoingCall, setIsOutgoingCall] = useState(false);
  const [isAcceptWindow, setIsAcceptWindow] = useState(false);
  const [isHangupWindow, setIsHangupWindow] = useState(false);
  const [isDeregistering, setIsDeregistering] = useState(false);
  const isDeregisteringRef = useRef(false); //set to true if deregistering is intentional so that onUserAgentDisconnect doesn't execute
  const [refreshWSConnection, setRefreshWSConnection] = useState(true);
  const [contactListId, setContactListId] = useState(null);

  const candidateNumberRef = useRef(null);

  const [inviteSession, setInviteSession] = useState({
    from: "",
    to: "",
    isSet: false,
    session: null,
  });
  const [callTime, setCallTime] = useState({
    session: null,
    time: { sec: 0, min: 0, hr: 0 },
    id: null,
  });
  const [updateCallStatusValues, setUpdateCallStatusValues] = useState({
    call_id: "",
    name: "",
    call_reason: "",
    call_outcome: "",
    notes: "",
    tags: [],
  });

  const callButtonRef = useRef();
  useEffect(() => {
    if (reduxSubscription && Object.keys(reduxSubscription)?.length > 0)
      calcTrialEnd(reduxSubscription);
  }, [reduxSubscription, canManageBillingSubscription]);

  useEffect(() => {
    if (accountDetails && accountDetails?.data?.profile) {
      dispatch(
        setSoftphone(
          accountDetails?.data?.access_list?.includes("BROWSER CALLING") &&
            accountDetails?.data?.profile?.browser_calls
        )
      );
    }
  }, [accountDetails?.data?.access_list, accountDetails?.data?.profile]);

  useEffect(() => {
    if (
      reduxSubscription?.status === "INACTIVE" &&
      (reduxSubscription?.payment_due_date ||
        isCurrentDate7DaysLaterThanTrialEnd(reduxSubscription?.end_date))
    ) {
      setIsAccountInactive(true);
      navigate("/account-suspended", { replace: true });
    } else if (reduxSubscription?.status === "INACTIVE") {
      //account is inactive but suspension message cannot be seen yet
      setIsAccountPreActivation(true);
      console.log("pre activation state .....");
    } else {
      if (isAccountInactive) setIsAccountInactive(false);
    }
  }, [reduxSubscription]);

  useEffect(() => {
    const root = document.querySelector(":root");
    if (subsEndInfo.visible && licenseDeletionInfo && trialDaysLeftInfo.trial)
      root.style.setProperty("--top-nav-height", "calc(4em + 168px)");
    else if (subsEndInfo.visible && trialDaysLeftInfo.trial)
      root.style.setProperty("--top-nav-height", "calc(4em + 108px)");
    else if (trialDaysLeftInfo.trial && licenseDeletionInfo)
      root.style.setProperty("--top-nav-height", "calc(4em + 108px)");
    else if (subsEndInfo.visible && licenseDeletionInfo)
      root.style.setProperty("--top-nav-height", "calc(4em + 108px)");
    else if (
      trialEndInfo.trial ||
      subsEndInfo.visible ||
      licenseDeletionInfo ||
      trialDaysLeftInfo.trial
    )
      root.style.setProperty("--top-nav-height", "calc(4em + 52px)");
    else root.style.setProperty("--top-nav-height", "4em");
  }, [
    trialEndInfo.trial,
    subsEndInfo.visible,
    licenseDeletionInfo,
    trialDaysLeftInfo.trial,
  ]);

  const isCurrentDate7DaysLaterThanTrialEnd = (date) => {
    const endDate = new Date(date);
    const today = new Date();
    const timeDifference = today.getTime() - endDate.getTime();
    const daysSince = Math.ceil(timeDifference / (1000 * 3600 * 24));
    if (daysSince >= 7) return true;

    return false;
  };

  const fetchTrialDaysLeftFromDate = (date) => {
    const endDate = new Date(date);
    const today = new Date();
    const timeDifference = endDate.getTime() - today.getTime();
    const daysRemaining = Math.ceil(timeDifference / (1000 * 3600 * 24));
    return daysRemaining;
  };

  const handleCountryCodeSelect = (val) => {
    return () => {
      setIsCCDropdownVisible(false);
      setSearchedCountryCode("");
      setSelectedCountryCode(val);
      localStorage.setItem("frejun-selected-country-code", val);
      setCountryCodeList([]);
    };
  };
  async function registerUser(userAgent, sipToken) {
    userAgent = userAgent ? userAgent : sip.userAgent;
    const registerOptions = {
      // 'all': true,
      expires: 600,
      ["extraHeaders"]: [`token: ${sipToken || sip?.sipToken}`],
    };
    let registerer = new Registerer(userAgent, registerOptions);
    if (
      !(
        sip.registerer &&
        [RegistererState.Registered, RegistererState.Initial].includes(
          sip.registerer.state
        )
      )
    ) {
      await registerer?.register({
        requestOptions: {
          extraHeaders: [`token: ${sipToken || sip?.sipToken}`],
        },
      });
      setSip((prevState) => ({
        ...prevState,
        userAgent,
        registerer,
        isRegistered: true,
      }));
    }
    // new de-register logic
    const opts = { capture: true };

    const unregisterOnFreeze = (e) => {
      // The page is now frozen.
      console.log("FROZEN");
      console.log("e?.persisted ", e?.persisted);
      // if (document.visibilityState === "passive" && !e?.persisted) {
      if (document.visibilityState === "hidden" && !e?.persisted) {
        registerer?.unregister({
          all: true,
          requestOptions: {
            ["extraHeaders"]: [`token: ${sipToken || sip?.sipToken}`],
          },
        });
        console.log(
          '2*UNREGISTERED IN visibilitychange when document.visibilityState === "passive"'
        );
      }
    };
    const unregisterOnPageHide = (e) => {
      console.log(
        "*************************PAGEHIDE ",
        e.persisted ? "frozen" : "terminated"
      );
      isDeregisteringRef.current = true;
      registerer?.unregister({
        all: true,
        requestOptions: {
          ["extraHeaders"]: [`token: ${sipToken || sip?.sipToken}`],
        },
      });
    };
    const unregisterOnUnload = async (e) => {
      e.preventDefault();
      isDeregisteringRef.current = true;
      registerer?.unregister({
        all: true,
        requestOptions: {
          ["extraHeaders"]: [`token: ${sipToken || sip?.sipToken}`],
        },
      });
      registerer?.dispose();
      userAgent?.stop();
      userAgent?._transport?.disconnect();
    };

    document.removeEventListener("freeze", unregisterOnFreeze, opts);
    window.removeEventListener("pagehide", unregisterOnPageHide, opts);
    window.removeEventListener("unload", unregisterOnUnload, opts);

    document.addEventListener("freeze", unregisterOnFreeze, opts);

    window.addEventListener("pagehide", unregisterOnPageHide, opts);

    window.addEventListener("unload", unregisterOnUnload, opts);
  }

  function dev_setStorage(key, value) {
    localStorage.setItem(key, value);
  }

  async function startUserAgent(sipToken, sipUsername) {
    let userAgent = null;

    sipToken = sipToken || sip?.sipToken;
    sipUsername = sipUsername || sip?.sipUsername;
    dev_setStorage("sipToken", sipToken);
    dev_setStorage("sipUsername", sipUsername);

    const userAgentOptions = {
      transportOptions: {
        server: "wss://call.bistux.com:9080",
        traceSip: true,
        connectionTimeout: 15,
      },
      logLevel: "debug",
    };

    userAgentOptions.uri = UserAgent.makeURI(
      `sip:${sipUsername}@call.bistux.com:9080`
    );

    userAgent = new UserAgent(userAgentOptions);

    sipUserAgent = userAgent;

    // add delegate
    userAgent.delegate = {
      onDisconnect: async function (error) {
        setSip((prev) => ({ ...prev, isRegistered: false }));
        await onUserAgentDisconnect(error);
      },
    };

    await userAgent.start();

    if (userAgent.isConnected()) {
      console.log("userAgent connected successfully"); // 📨
    } else {
      // show error message
      console.error(userAgent);
      return;
    }
    setSip((prevState) => ({
      ...prevState,
      setup: "start",
      userAgent: userAgent,
      sipToken,
      sipUsername,
    }));

    await registerUser(userAgent, sipToken);
  }

  async function deregisterSoftphone() {
    try {
      setIsDeregistering(true);
      isDeregisteringRef.current = true;
      const unregisterRes = await sip?.registerer?.unregister({
        all: true,
        requestOptions: { ["extraHeaders"]: [`token: ${sip?.sipToken}`] },
      });
      console.log("unregisterRes", unregisterRes);
      setSip((prev) => ({ ...prev, isRegistered: false }));
      const disposeRes = await sip?.registerer?.dispose();
      console.log("disposeRes", disposeRes);
      const uaStopRes = await sip?.userAgent?.stop();
      console.log("uaStopRes", uaStopRes);
      const transportDisconnectRes =
        await sip?.userAgent?._transport?.disconnect();
      console.log("transportDisconnectRes", transportDisconnectRes);
      setSip({
        sipToken: "",
        sipUsername: "",
        isOpen: false,
        setup: "idle",
        userAgent: null,
        registerer: null,
        isRegistered: false,
        isCall: false,
      });
      setInviteSession({ from: "", to: "", isSet: false, session: null });
    } catch (err) {
      console.log("deregister softphone error", err);
    }
  }

  async function onUserAgentDisconnect(error) {
    try {
      console.log(
        "isDeregistering",
        isDeregistering,
        isDeregisteringRef.current
      );
      if (isDeregistering || isDeregisteringRef.current == true) {
        isDeregisteringRef.current = false;
        setIsDeregistering(false);
        return;
      }
      if (!error) {
        console.error("Softphone disconnected with undefined error", error);
      } else console.log("TRANSPORT ERROR", error);

      // transport not connected || !registererd
      const currentRegisterationState = sip?.registerer?.state;
      if (
        !sip.userAgent?.isConnected() ||
        currentRegisterationState !== RegistererState.Registered
      ) {
        // token and username must be there otherwise call register-softphone again
        if (sip?.sipToken && sip?.sipUsername) {
          console.log("found token and username, starting user agent ");
          await startUserAgent();
        } else {
          await registerSoftphone();
          await startUserAgent();
        }
      } else {
        console.log("transport connected || registererd");
      }
    } catch (err) {
      console.error("onUserAgentDisconnect error", err);
    }
  }

  const getCountryCodeAndNumber = (phoneNumber) => {
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    try {
      const number = phoneUtil.parseAndKeepRawInput(phoneNumber);
      return [number.getCountryCode(), number.getNationalNumber()];
    } catch (err) {
      console.log(err);
    }
  };
  const validatePhoneNumber = (cc, phoneNumber) => {
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    try {
      const parsedNumber = phoneUtil.parseAndKeepRawInput(
        cc ? cc + phoneNumber : "" + phoneNumber
      );

      return phoneUtil.isValidNumber(parsedNumber);
    } catch (error) {
      console.log("Error while validating phonenumber as", error);
    }
  };
  function getCurrentTrackState(senders) {
    const testStream = senders.at(0);
    let currentTrackState = isMute;
    if (testStream.track && testStream.transport) {
      currentTrackState = testStream.track.enabled;
    }
    return currentTrackState;
  }
  const handleMute = () => {
    if (!inviteSession?.session) return;
    const senders =
      inviteSession.session.sessionDescriptionHandler.peerConnection.getSenders();
    senders.forEach((sender) => {
      if (sender?.track && sender?.track?.kind === "audio") {
        sender.track.enabled = isMute;
      }
    });
    setIsMute(!isMute);
  };
  const handleHold = () => {
    try {
      if (!inviteSession?.session) return;
      const sessionDescriptionHandlerOptions =
        inviteSession.session?.sessionDescriptionHandlerOptionsReInvite;
      // adding hold
      console.log(
        "sessionDescriptionHandlerOptions",
        sessionDescriptionHandlerOptions
      );
      console.log("session", inviteSession.session);

      // to hold/un-hold
      sessionDescriptionHandlerOptions.hold = !isHold;

      let peerConnection =
        inviteSession.session?.sessionDescriptionHandler?.peerConnection;

      let senders = peerConnection.getSenders();

      const currentTrackState = getCurrentTrackState(senders);

      // check current state, i.e.muted/unmuted
      // let isEnabled = null;
      // console.log("sender BEFORE toggling hold:", sender);
      // sender.forEach(function (stream) {
      //   if (stream.track && stream.transport) {
      //   if (isEnabled === null) isEnabled = stream.track.enabled;
      //   }
      // });
      // isMuted = !isEnabled;

      const inviteOptions = {
        requestDelegate: {
          onAccept: () => {
            const sessionDescriptionHandler =
              inviteSession.session.sessionDescriptionHandler;
            sessionDescriptionHandler?.enableSenderTracks(!currentTrackState);
            sessionDescriptionHandler?.enableReceiverTracks(isHold);
          },
          onReject: () => {
            const sessionDescriptionHandler =
              inviteSession.session.sessionDescriptionHandler;
            sessionDescriptionHandler?.enableReceiverTracks(false);
            sessionDescriptionHandler?.enableSenderTracks(false);
          },
        },
      };

      // inviting
      (async function () {
        try {
          const curSession = inviteSession.session;
          curSession.sessionDescriptionHandlerOptionsReInvite =
            sessionDescriptionHandlerOptions;
          const inviteResponse = await curSession.invite(inviteOptions);

          setIsHold((prevState) => !prevState);
        } catch (error) {
          // onError check if actually muted or not
          let isEnabled = null;
          senders.forEach(function (stream) {
            if (stream.track && stream.transport) {
              if (isEnabled === null) isEnabled = stream.track.enabled;
            }
          });

          setIsHold(isEnabled);
          console.error("reinvite for hold error:", error);
        }
      })();
    } catch (err) {
      console.warn("hold error:", err);
    }
    return true;
  };

  function handleUnloadTab(e) {
    //setImmediate(() => sip?.registerer?.dispose());
    console.log("onBeforeunload");
    e.preventDefault();
    e.returnValue = "";
    console.log("after also");
    // sip?.userAgent?.stop()
    // let allRegisterers = sip?.userAgent._registerers
    // for (const id in allRegisterers) {
    // 	console.log('---------------------------------------------**checking ', allRegisterers[id])
    // 	if (allRegisterers[id]) {
    // 		if(allRegisterers[id]._contacts)
    // 		allRegisterers[id].dispose()
    // 		// .catch((error) => {
    // 		// 	throw error;
    // 		// });
    // 	}
    // }
  }

  function handleSipAction(action = "reject") {
    let localErr = false;
    try {
      if (inviteSession.session === null || !inviteSession?.isSet) {
        localErr = true;
        throw new Error(
          `Invalid value for "inviteSession.session". Expected "Session", got ${inviteSession?.session}`
        );
      }

      if (action === "accept") {
        inviteSession.session?.accept();

        //startTimer(); //disabled for now

        // if(!isIncomingCall) setIsIncomingCall(true) // @duplicate
        setCurrentScreenInCallingDialerFlow(2); // if accept is clicked; call-details page with end-call
        if (!sip?.isCall)
          setSip((prevState) => ({ ...prevState, isCall: true }));
      } else if (action === "reject") {
        // incoming not established
        inviteSession.session?.reject();

        if (inviteSession?.isSet || inviteSession?.session !== null)
          setInviteSession((prevState) => ({
            ...prevState,
            session: null,
            isSet: false,
          }));

        if (isIncomingCall) setIsIncomingCall(false);
        if (isHangupWindow) setIsHangupWindow(false);
        if (isOutgoingCall) setIsOutgoingCall(false);

        if (!callTime?.id && callTime?.id !== 0) {
          localErr = true;
          throw new Error(
            `Invalid value for "id" of callTimer, got ${callTime?.id}`
          );
        }
        // console.log("calltime id being cleared", callTime.id);
        // clearInterval(callTime.id);
        // setCallTime((prevState) => ({
        //   ...prevState,
        //   session: null,
        //   //id: null
        // }));
        // setClearTimer(true);  //uncomment if timer is enabled
      } else if (action === "bye") {
        // for established session
        console.log(inviteSession.session);
        inviteSession.session?.bye();

        if (inviteSession?.isSet || inviteSession?.session !== null)
          setInviteSession((prevState) => ({
            ...prevState,
            session: null,
            isSet: false,
          }));

        if (isIncomingCall) setIsIncomingCall(false);
        if (isHangupWindow) setIsHangupWindow(false);
        if (isOutgoingCall) setIsOutgoingCall(false);
        if (isAcceptWindow) setIsAcceptWindow(false);

        if (!callTime?.id && callTime?.id !== 0) {
          localErr = true;
          throw new Error(
            `Invalid value for "id" of callTimer, got ${callTime?.id}`
          );
        }
        // console.log("calltime id being cleared", callTime.id);
        // clearInterval(callTime.id);
        // setCallTime((prevState) => ({
        //   ...prevState,
        //   session: null,
        //   // id: null
        // }));
        //setClearTimer(true); //uncomment if timer is enabled
      } else if (action === "cancel") {
        inviteSession.session?.cancel();

        if (inviteSession?.isSet || inviteSession?.session !== null)
          setInviteSession((prevState) => ({
            ...prevState,
            session: null,
            isSet: false,
          }));

        if (isIncomingCall) setIsIncomingCall(false);
        if (isHangupWindow) setIsHangupWindow(false);
        if (isOutgoingCall) setIsOutgoingCall(false);
        if (isAcceptWindow) setIsAcceptWindow(false);

        if (!callTime?.id && callTime?.id !== 0) {
          localErr = true;
          throw new Error(
            `Invalid value for "id" of callTimer, got ${callTime?.id}`
          );
        }

        //setClearTimer(true); //uncomment if timer is enabled
      } else throw new Error(`Invalid value for "action"`);

      if (action !== "accept") {
        setSip((prevState) => ({ ...prevState, isCall: false }));
        setInviteSession({ from: "", to: "", session: null, isSet: false });
      }
    } catch (error) {
      if (localErr) setSip((prevState) => ({ ...prevState, isCall: false }));
    }
  }

  function startTimer() {
    // something fishy
    // if(!remoteAudio) throw new Error("Unexpected, remote audio not found in document tree")
    // if(!(remoteAudio.srcObject instanceof MediaStream)) throw new Error("Unexpected, remote audio not found in document tree")
    // if (callTime.time !== 0) throw new Error("Unexpected, timer is not 0")

    // console.log('condn check 1', !(remoteAudio.srcObject instanceof MediaStream)) // updated the condition; check the proto
    // if(remoteAudio.srcObject.__proto__.isPrototypeOf(new MediaStream())) // FIXME: susceptible to prototype pollution

    console.log("condn check 2", callTime.time !== 0);

    let t = null;
    let t_id = setInterval(() => {
      if (t === null) {
        setCallTime((prevState) => ({
          ...prevState,
          //session: inviteSession?.session,
          id: t_id,
        }));
        console.log("timer id set", t_id);
      }
      t = callTime;

      // setCallTime((prevState) => ({
      //   ...prevState,
      //   time:
      //     t.time instanceof Number || typeof t.time === "number"
      //       ? prevState.time + 1
      //       : 0,

      // }));
      setCallTime((prev) => {
        let sec = 0,
          min = 0,
          hr = 0;
        if (
          prev.time.sec instanceof Number ||
          typeof prev.time.sec === "number"
        )
          sec = prev.time.sec + 1;
        else sec = 1;
        if (sec === 60) {
          sec = 0;
          min = prev.time.min + 1;
        } else {
          min = prev.time.min;
        }
        if (min === 60) {
          min = 0;
          hr = prev.time.hr + 1;
        } else {
          hr = prev.time.hr;
        }
        console.log(hr, min, sec);
        return { ...prev, time: { sec, min, hr } };
      });

      t.time =
        (t.time instanceof Number || typeof t.time === "number" ? t.time : 0) +
        1;
      if (!t.id && t.id !== 0) t.id = t_id;
      // setCallTime(((t.time-1 > 0 && t.time-1) || (t.time)))

      // setCallTime(t)
      //console.log(t);
      // console.log('callTime ', callTime)
      // setBadId(prevState => )
    }, 1000);
  }

  const [changingVirtualNumber, setChangingVirtualNumber] = useState(false);

  async function changeVirtualNumber(e, phoneNumber) {
    setChangingVirtualNumber(true);
    let newPhoneNumber = e?.target?.value || phoneNumber;
    // update via api call also
    const updateSelectedVnResponse =
      await updateVirtualNumberInUserProfile(newPhoneNumber);
    //update in redux also
    if (updateSelectedVnResponse?.success) {
      setSelectedCountryCode(newPhoneNumber?.country_code);
      localStorage.setItem(
        "frejun-selected-country-code",
        newPhoneNumber?.country_code
      );
      enqueueSnackbar(updateSelectedVnResponse?.message, {
        variant: "success",
      });
      setSelectedOutboundNumber(updateSelectedVnResponse.data || "");
      dispatch(
        reduxPersistSelectedVirtualNumber(updateSelectedVnResponse.data || "")
      );
    } else
      enqueueSnackbar(
        updateSelectedVnResponse?.message + ". Please try again.",
        { variant: "error" }
      );
  }

  async function updateVirtualNumberInUserProfile(newPhoneNumber) {
    try {
      // errorMessage shown in parent
      if (!("id" in newPhoneNumber))
        throw new Error(
          "Invalid newPhoneNumber, expected phoneNumber object, got ",
          newPhoneNumber
        );

      let response = await JService.patch("/api/v1/auth/update-userprofile/", {
        primary_virtual_number_id: newPhoneNumber.id,
      });
      if (response.status === 500)
        throw new Error(
          "Server error please try again or if issue persists contact FreJun"
        );
      if (!response?.success) throw new Error(response?.message || "");

      console.log("response after updating phoneNumber ", response);
      dispatch(
        saveAccountDetails({
          ...accountDetails,
          data: { ...accountDetails.data, profile: response.data },
        })
      );
      return {
        message: "Successfully updated primary virtual number",
        data: response.data?.primary_virtual_number,
        success: true,
      };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        message:
          error?.message + " " + " Error while updating primary virtual number",
        data: null,
      };
    } finally {
      setChangingVirtualNumber(false);
    }
  }

  //uncomment if timer is enabled
  // useEffect(() => {
  //   if (clearTimer) {
  //     console.log("calltime:", callTime);
  //     clearInterval(callTime.id);
  //     setCallTime((prev) => ({ ...prev, session: null }));
  //     setClearTimer(false);
  //   }
  // }, [clearTimer]);

  useEffect(() => {
    fetchLocations();
    window.addEventListener("offline", handleOffline);
    return () => {
      window.removeEventListener("offline", handleOffline);
      // update vn in localstorage
      localStorage.setItem("frejun-selected-country-code", selectedCountryCode);
    };
  }, []);

  // FIXME: success missing in response
  async function fetchLocations() {
    try {
      const json = await JService.get("/api/v1/auth/locations/"); //await fetch(API_BASE_URL + "/api/v1/auth/locations/")
      // if(!json.success) throw new Error(json?.message)
      // removing duplicates since there were 3 india's with differenct cities in response
      let temp = [];
      let seen = new Set();
      for (var item of json) {
        if (!seen.has(item.country)) {
          temp.push(item);
          seen.add(item.country);
        }
      }
      setCCData(temp);
    } catch (error) {
      enqueueSnackbar(error?.message || "Could not fetch country codes", {
        variant: "error",
      });
      console.error(error);
    }
  }

  function handleOffline() {
    if (navigator.onLine) return;
    console.log("navigator not online ran!");
    window.addEventListener("online", handleBackOnline);
    setCurrentScreenInCallingDialerFlow(-3);
  }
  useEffect(() => {
    console.log("virtualNumberState changed:", virtualNumberState);
    if (
      !selectedOutboundNumber &&
      virtualNumberState?.outbound_virtual_numbers
    ) {
      if (!virtualNumberState?.outboundVirtualNumber) {
        const callingOutboundNumbers =
          virtualNumberState?.outbound_virtual_numbers?.filter(
            (number) => number.type === "Calling"
          );
        if (callingOutboundNumbers?.length > 0) {
          // select the primary virtual number otherwise set this and update via api
          console.log("virtual number state ", virtualNumberState);
          console.log("outboundVns ", outboundVirtualNumbers);

          setOutboundVirtualNumbers(callingOutboundNumbers);
          // setSelectedOutboundNumber(virtualNumberState?.outboundVirtualNumber || callingOutboundNumbers[0])
          setSelectedOutboundNumber(callingOutboundNumbers[0]);
        } else {
          setOutboundVirtualNumbers([]);
          setSelectedOutboundNumber("");
        }
      } else {
        setSelectedOutboundNumber(
          virtualNumberState?.outboundVirtualNumber || ""
        );
        setOutboundVirtualNumbers(
          virtualNumberState?.outbound_virtual_numbers?.filter(
            (number) => number.type === "Calling"
          )
        );
      }
    }
  }, [virtualNumberState]);

  useEffect(() => {
    setCallBtnDisabled(
      !outboundVirtualNumbers ||
        outboundVirtualNumbers?.length === 0 ||
        (outboundVirtualNumbers.length > 1 && selectedOutboundNumber === "") ||
        (callingValue?.length < 5 &&
          globalDialerState?.phone_number?.length < 5)
    );
  }, [
    outboundVirtualNumbers,
    selectedOutboundNumber,
    callingValue,
    globalDialerState?.phone_number,
  ]);

  useEffect(() => {
    if (globalDialerState?.isVisible) {
      // opening dialer
      window.scrollTo({ top: 0, behavior: "auto" });
      //if (globalDialerState?.phone_number?.length === 10) {
      //setCallingValue(globalDialerState?.phone_number);

      setIsCallingDialerDropdownVisible(true);
      const anchorEl = document.querySelector("#dialpad-icon");
      setCurrentScreenInCallingDialerFlow(0);
      setCallingDialerDropdownAnchorEl(anchorEl);

      if (outboundVirtualNumbers?.length) {
        handleCall("call-btn");
      }
    }
  }, [globalDialerState]);

  /***************functions called on clicking refresh in dialer error screens**************/
  const refreshCallNotInitiated = async () => {
    await onUserAgentDisconnect();
    setCurrentScreenInCallingDialerFlow(0);
  };

  const fetchVirtualNumbers = () => {
    JService.get("/api/v1/auth/retrieve-virtual-numbers/")
      .then((res) => {
        if (!res?.success)
          throw new Error(res?.message || "Could not fetch virtual numbers");

        dispatch(setVirtualNumber(res?.data));
        if (res?.data?.outbound_virtual_numbers) {
          const callingOutboundNumbers =
            res?.data?.outbound_virtual_numbers?.filter(
              (number) => number.type === "Calling"
            );
          if (callingOutboundNumbers?.length > 0) {
            setOutboundVirtualNumbers(callingOutboundNumbers);
            setCurrentScreenInCallingDialerFlow(0);
            if (
              callingOutboundNumbers.findIndex(
                (vn) => vn?.id == virtualNumberState?.outboundVirtualNumber?.id
              ) === -1
            ) {
              console.warn(
                "invalid vn set in store, using fresh vn from response"
              );
              setSelectedOutboundNumber(callingOutboundNumbers[0]);
            }
          } else {
            setCurrentScreenInCallingDialerFlow(-1);
          }
        } else {
          setCurrentScreenInCallingDialerFlow(-1);
        }
      })
      .catch((err) =>
        enqueueSnackbar(err?.message || "Could not fetch virtual numbers", {
          variant: "error",
        })
      );
  };

  async function handleBackOnline() {
    try {
      if (!navigator.onLine) return;
      window.removeEventListener("online", handleBackOnline);

      if (sip.userAgent) {
        if (sip.userAgent.state !== UserAgentState.Started) {
          await sip.userAgent.start();
        } else {
          await sip.userAgent.reconnect(); // moveto Transport.onDisconnect
        }
      } else {
        setRefreshWSConnection((prev) => prev + 1);
      }

      // const currentRegisterationState = sip?.registerer?.state;
      // // this was not running it was in state "INITIAL"
      // if (
      //   [RegistererState.Registered, RegistererState.Initial].includes(
      //     currentRegisterationState
      //   )
      // ) {
      //   console.log("* softphone registration confirmed");
      //   setSip((prev) => ({ ...prev, isRegistered: true }));
      //   setCurrentScreenInCallingDialerFlow(0);
      // } else {
      //   const reRegisterReponse = await registerUser(sip?.userAgent);
      //   console.log("now online and reRegisterReponse ", reRegisterReponse);
      //   setCurrentScreenInCallingDialerFlow(0);
      // }
      return true;
    } catch (error) {
      console.error("error handleBackOnline ", error);
      console.error("error stack ", error?.stack);
      console.error("error message ", error?.message);
      setCurrentScreenInCallingDialerFlow(-3);
    }
  }
  /************************************************************************************/

  async function registerSoftphone() {
    try {
      let isTokenExpired = true;
      let data = null;
      if (localStorage.getItem("frejun-sip-data")) {
        data = JSON.parse(localStorage.getItem("frejun-sip-data"));
        const decoded_token = jwt_decode(data.access_token);
        isTokenExpired =
          Date.now() > new Date(decoded_token.exp * 1000 - 60000);
      }
      if (!isTokenExpired)
        return {
          success: true,
          username: data.username,
          access_token: data.access_token,
        };
      const res = await JService.get("/api/v1/calls/register-softphone/");

      if (!res?.success)
        throw new Error(res?.message || "Could not register softphone");

      // window.addEventListener('unload', handleUnloadTab)

      // de-register logic
      // if(!window.onbeforeunload) {
      // 	console.log('window.onbeforeunload ', window.onbeforeunload)
      // 	window.addEventListener('beforeunload', async (e) => await runDeregisterSequenceBeforeunload(e))

      // } else {
      // 	console.log('ALREADY THERE!!!window.onbeforeunload ', window.onbeforeunload)
      // 	window.onbeforeunload = async (e) => await runDeregisterSequenceBeforeunload(e)
      // }

      // window.addEventListener("beforeunload", (e) => {
      //   e.preventDefault();
      //   sip?.userAgent?.stop();

      //   console.log("this happened 1");

      //   sip?.registerer?.unregister();
      //   console.log("this happened 2");

      //   e.returnValue = "";
      //   console.log("this happened 3");
      //   if (!sip?.isRegistered)
      //     throw new Error("This should not have ran, ran on !isRegistered");
      // });
      localStorage.setItem(
        "frejun-sip-data",
        JSON.stringify({
          username: res.username,
          access_token: res.access_token,
        })
      );
      return res;
    } catch (err) {
      return {
        success: false,
        message: `Error while registering softphone ${err}`,
        access_token: null,
        username: null,
      };
    }
  }

  useEffect(() => {
    (async () => {
      if (accountDetails && browserCalls && refreshWSConnection !== 0) {
        try {
          const registerResponse = await registerSoftphone();
          if (!registerResponse.success)
            throw new Error(registerResponse.message);
          // username and token to register with
          let sipUsername = registerResponse?.username;
          let sipToken = registerResponse?.access_token;
          setSip((prev) => ({ ...prev, sipToken, sipUsername }));
          const startUAResponse = await startUserAgent(sipToken, sipUsername);
          setRefreshWSConnection(0);
        } catch (error) {
          if (error.message?.includes("1006") && refreshWSConnection < 6) {
            setRefreshWSConnection((prev) => prev + 1);
            enqueueSnackbar(
              "WebSocket error occurred, please refresh the page or contact FreJun support if the error persists.",
              { variant: "error" }
            );
          } else {
            enqueueSnackbar(error?.message, { variant: "error" });
          }
        }
      }
    })();

    // return async () => {
    //   if(sip?.userAgent?.state === UserAgentState.Started){
    //     await sip?.userAgent?.stop();
    //   }
    // }
    // return async () => {
    //   console.log('in cleanup function', sipUserAgent);
    //   isDeregisteringRef.current = true;
    //   await sipUserAgent?.stop();
    //   await sipUserAgent?._transport?.disconnect();
    // sip?.registerer?.unregister({
    //   all: true,
    //   requestOptions: {
    //     ["extraHeaders"]: [`token: ${sip?.sipToken}`],
    //   },
    // });
    // sip?.registerer?.dispose();
    // sip?.userAgent?.stop();
    // sip?.userAgent?._transport?.disconnect();
    // };
  }, [refreshWSConnection, browserCalls]);
  // fetching data
  useEffect(() => {
    (async () => {
      setCurrentScreenInCallingDialerFlow(0);
      if (outboundVirtualNumbers.length === 0) {
        fetchVirtualNumbers();
      }

      // setting orgDetails for call_reason and call_outcome

      let tempOrgDetails = null;
      let tempOrgDetailsFix = null;
      if (accountDetails?.data?.profile?.account_holder) {
        try {
          let res = await JService.get("/api/v1/auth/organizations/");
          if (!res?.success)
            throw new Error(res?.message || "Could not retrieve organizations");
          setAllOrgs(res?.data);
          // console.log('FINAL Resdata: ', res?.data)
          dispatch(setAllOrgsDetails(res?.data));
          tempOrgDetails = res?.data;
          // console.log('all Orgs details from fetch: ', allOrgs)
          let checkAgainstId =
            reduxSelectedOrg || reduxSelectedOrg === 0
              ? reduxSelectedOrg
              : null;
          let currentOrgItem = checkAgainstId
            ? res?.data?.find((rd) => rd?.id === checkAgainstId)
            : res?.data?.find((rd) => rd?.primary_account);

          if (
            currentOrgItem?.subscription &&
            Object.keys(currentOrgItem?.subscription)?.length
          ) {
            dispatch(
              setSubscriptionDetails(currentOrgItem?.subscription || {})
            );
          }
          if (
            currentOrgItem?.credit &&
            Object.keys(currentOrgItem?.credit)?.length
          ) {
            dispatch(setCredits(currentOrgItem?.credit || {}));
          }
          setOrgDetails(currentOrgItem || {});
          //calcTrialEnd(currentOrgItem?.subscription || {})
          // console.log('currentOrgItem is: ', currentOrgItem)
          // CLARIFY: for some accounts response.data == [], is it expected ?
          // after adding the first account from the '+ Add account' option, is data?.length == 2 in this response ?
          // how to get organisation name if data == []
        } catch (err) {
          enqueueSnackbar(err?.message || "Could not retrieve organizations", {
            variant: "error",
          });
        }
      }
      // call retreive-org/ if either !account_holder or for some reason retreive-orgs/ returned [] in response.data
      if (
        !accountDetails?.data?.profile?.account_holder ||
        (Array.isArray(tempOrgDetails) && tempOrgDetails?.length === 0)
      ) {
        try {
          const res = await JService.get("/api/v1/auth/retrieve-organization/");
          if (!res?.success)
            throw new Error(
              res?.message ||
                "Could not retreive options for call reason and outcome"
            );
          if (
            res?.data?.subscription &&
            Object.keys(res?.data?.subscription)?.length
          ) {
            dispatch(setSubscriptionDetails(res?.data?.subscription || {}));
          }
          if (res?.data?.credit && Object.keys(res?.data?.credit)?.length) {
            dispatch(setCredits(res?.data?.credit || {}));
          }
          setOrgDetails(res?.data || {});
          tempOrgDetailsFix = res?.data;
          setAllOrgs({});
          dispatch(saveOrgId(res?.data?.id ?? ""));
        } catch (err) {
          enqueueSnackbar(
            err?.message ||
              "Could not retreive options for call reason and outcome",
            { variant: "error" }
          );
        }
      }

      // if !account_holder nothing else needs to be done,
      // else if account_holder == true and "retreive-orgs/" res.data == [] =then> set orgDetails (for call-reason&outcome etc.) to be available locally and dispatch redux setAllOrgsDetails
      if (
        accountDetails?.data?.profile?.account_holder &&
        Array.isArray(tempOrgDetails) &&
        tempOrgDetails?.length === 0
      ) {
        setOrgDetails(tempOrgDetailsFix);
        dispatch(setAllOrgsDetails([tempOrgDetailsFix]));
      }

      if (
        !(
          !accountDetails ||
          Object.keys(accountDetails).length === 0 ||
          !accountDetails.data ||
          Object.keys(accountDetails.data)?.length === 0
        )
      ) {
        if (
          window.location.pathname?.includes("signup") ||
          window.location.pathname?.includes("card-details")
        )
          return;
        JService.get(API_BASE_URL + "/api/v1/auth/retrieve-account-profile/")
          .then((res) => {
            if (res?.success) {
              console.log("res", res);
              dispatch(saveAccountDetails(res));
              if (res?.data?.profile?.primary_virtual_number)
                dispatch(
                  reduxPersistSelectedVirtualNumber(
                    res?.data?.profile?.primary_virtual_number
                  )
                );
            } else
              throw Error(res?.message || "Failed to fetch account details");
          })
          .catch((err) =>
            console.log(
              err?.message || "Error occurred while fetching account details"
            )
          );
      }
    })();
  }, []);

  const handlePayNow = () => {
    setPayingInvoice(true);
    JService.get("/api/v1/subscriptions/pay-now")
      .then((res) => {
        if (!res?.success) {
          throw new Error(res?.message || "Something went wrong");
        }
        if (res?.url) {
          window.location.href = res.url;
        }
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(err?.message || "Something went wrong", {
          variant: "error",
        });
      })
      .finally(() => {
        setPayingInvoice(false);
      });
  };
  function calcTrialEnd(res) {
    console.log(res?.payment_due_date);
    if (res?.status === "TRIAL") {
      let message = "";
      if (Date.now() > new Date(`${res?.end_date}T23:59:59`)) {
        message = (
          <p>
            Your FreJun Professional plan trial has <b>ended</b>
          </p>
        );
        setTrialEndInfo({ trial: true, message });
      } else {
        setTrialEndInfo({ trial: true, message: "" });
        setTrialDaysLeftInfo({
          trial: true,
          message: (
            <p className="t7 regular-font">
              <span>Your FreJun Professional plan trial ends in </span>{" "}
              <u>{fetchTrialDaysLeftFromDate(res?.end_date)} days</u>
            </p>
          ),
        });
      }
    } else if (res?.status === "INACTIVE") {
      if (!res?.payment_due_date) {
        setTrialEndInfo({
          trial: true,
          message: (
            <p>
              Your FreJun Professional plan trial has <b>ended</b>
            </p>
          ),
        });
        setTrialDaysLeftInfo({ trial: false, message: "" });
      } else {
        setTrialEndInfo({ trial: false, message: "" });
        setTrialDaysLeftInfo({ trial: false, message: "" });
      }
    }

    if (res?.status === "ACTIVE" && canManageBillingSubscription) {
      if (
        res?.end_date &&
        res?.payment_due_date &&
        Date.now() <= new Date(`${res?.payment_due_date}T23:59:59`) &&
        new Date(res?.end_date) >= new Date(res?.payment_due_date)
      ) {
        setSubsEndInfo({
          visible: true,
          message: (
            <p className="t7 regular-font">
              <span className="medium-font">Payment pending</span> - Please make
              your payment by <u>{formatDate(res?.payment_due_date)}</u> to
              prevent account suspension.
            </p>
          ),
          btnText: "Pay now",
          btnClick: handlePayNow,
        });
      } else {
        setSubsEndInfo({
          visible: false,
          message: "",
          btnText: "",
          btnClick: () => null,
        });
      }
    } else {
      setSubsEndInfo({
        visible: false,
        message: "",
        btnText: "",
        btnClick: () => null,
      });
    }

    if (res?.status === "ACTIVE" && canManageBillingSubscription) {
      if (
        res?.plan_licenses?.deleted > 0 ||
        res?.viewer_licenses?.deleted > 0
      ) {
        setLicenseDeletionInfo(true);
      }
    } else {
      setLicenseDeletionInfo(false);
    }
  }

  const [controller, setController] = useState(0);
  const allTagsRef = useRef([]);

  useEffect(() => {
    JService.get(`/api/v1/auth/retrieve-tags/${getOrgURLParam()}`)
      .then((res) => {
        if (!res.success)
          throw new Error(res?.message || "Could not retreive tags");
        // setAllTags(res?.data?.map(rd => ({name: rd?.name, id: rd?.id})))
        allTagsRef.current = res?.data;
        // dispatchAvailableData({type: 'setPayloadToAllOfType', payload:{payloadFor: 'tags', payload: res?.data?.map(rd => ({name: rd?.name, id: rd?.id}))}})
      })
      .catch((err) =>
        enqueueSnackbar(err?.message || "Failed to retreive tags", {
          variant: "error",
        })
      );
  }, [controller]);

  useEffect(() => {
    // console.log('all Orgs details from effect: ', allOrgs)
    // console.log(' and orgDetails: ', orgDetails)
    if (
      allOrgs !== null &&
      allOrgs?.length === 0 && // allOrgs response is complete and allOrgs == []
      Object.keys(orgDetails)?.length > 0 // orgDetails response is complete and res.success == true
    ) {
      // use orgDetails to set add-account dropdown options
      const orgObject = {
        id: orgDetails?.id,
        name: orgDetails?.name,
        company: orgDetails?.company,
      };
      setAllOrgs([orgObject]);
      // dispatch(setAllOrgsDetails(orgObject)) // OLD
      dispatch(setAllOrgsDetails([orgObject])); // NEW
      if (!reduxSelectedOrg) setSelectedOrg(orgDetails?.id);
      dispatch(saveSelectedOrg(orgDetails?.id));
    } else if (
      allOrgs &&
      Object.keys(allOrgs)?.length == 0 &&
      !Array.isArray(allOrgs)
    ) {
      // fix for user
      // allOrgs is set to {}
      const orgObject = {
        id: orgDetails?.id,
        name: orgDetails?.name,
        company: orgDetails?.company,
      };
      setAllOrgs([orgObject]);
      dispatch(setAllOrgsDetails([orgObject]));
      if (!reduxSelectedOrg) setSelectedOrg(orgDetails?.id);
      dispatch(saveSelectedOrg(orgDetails?.id));
    }
    // else if(allOrgs?.length > 0 && allOrgs?.findIndex(ao => ao?.id === orgDetails?.id) === -1) {
    // 	console.log('2nd block ***** else if')
    // 	if(reduxSelectedOrg === '') {
    // 		// if nothing is set rest it to base organization
    // 		console.log(orgDetails.id, ' orgDetails.id')
    // 		setSelectedOrg(orgDetails?.id)
    // 		dispatch(saveSelectedOrg(orgDetails?.id))
    // 	}
    // 	//  else if()
    // }
    else if (allOrgs?.length !== 0) {
      // console.log('3RD block ***** else if')
      if (!reduxSelectedOrg) {
        // console.log('MAIN ORGDETAILS: ', orgDetails)
        setSelectedOrg(orgDetails?.id);
        dispatch(saveSelectedOrg(orgDetails?.id));
      } else {
        // console.log('******in the golden else: ', reduxSelectedOrg)
        setSelectedOrg(reduxSelectedOrg);
      }
    }
  }, [allOrgs, orgDetails]);

  useEffect(() => {
    if (
      (!reduxSelectedOrg && reduxSelectedOrg !== 0) ||
      !accountDetails?.allOrgs ||
      accountDetails?.allOrgs?.length <= 1
    )
      return;
    const selectedOrgDetails = accountDetails?.allOrgs?.find?.(
      (org) => org.id === reduxSelectedOrg
    );
    if (
      selectedOrgDetails?.subscription &&
      Object.keys(selectedOrgDetails?.subscription)?.length
    ) {
      dispatch(setSubscriptionDetails(selectedOrgDetails?.subscription));
    }
    if (
      selectedOrgDetails?.credit &&
      Object.keys(selectedOrgDetails?.credit)?.length
    ) {
      dispatch(setCredits(selectedOrgDetails?.credit));
    }
  }, [reduxSelectedOrg, accountDetails?.allOrgs]);

  useEffect(() => {
    // make call to fetch user details
    if (
      !accountDetails ||
      Object.keys(accountDetails).length === 0 ||
      !accountDetails.data ||
      Object.keys(accountDetails.data)?.length === 0
    ) {
      if (
        window.location.pathname?.includes("signup") ||
        window.location.pathname?.includes("card-details")
      )
        return;
      JService.get("/api/v1/auth/retrieve-account-profile/")
        .then((res) => {
          if (res?.success) {
            console.log("res", res);
            dispatch(saveAccountDetails(res));
            setUserStatus(res?.data?.profile?.status);
            if (res?.data?.profile?.primary_virtual_number)
              dispatch(
                reduxPersistSelectedVirtualNumber(
                  res?.data?.profile?.primary_virtual_number
                )
              );
            if (
              window.location.pathname !== "/call-logs" &&
              window.location.pathname !== "/dashboard"
            )
              navigate("/call-logs", { replace: true });
          } else throw Error(res?.message || "Failed to fetch account details");
        })
        .catch((err) =>
          console.log(
            err?.message || "Error occurred while fetching account details"
          )
        );
    }
  }, [accountDetails?.data]);

  // ⏫⬆️ otherscreens validation [priority: high] [ERROR on call initiate]
  // add phone number validation so that the button is disabled

  const handleCallingValueChange = (e) => {
    let _value = e.target.value;
    //if (_value.trim().length <= 10)
    _value = _value.replace(/[^\d]/g, "");
    setCallingValue(_value.trim());
    //else setCallingValue(_value?.trim()?.substr(0, 10));
  };
  const handlePastedCallingValue = (e) => {
    e.preventDefault();
    let ccInPastedNumber = false;
    let _value = e.clipboardData.getData("text").trim();
    if (_value?.startsWith("+")) ccInPastedNumber = true;
    _value = _value.replace(/[^\d]/g, "");
    if (_value?.length > 5 && ccInPastedNumber) {
      const ccAndNumber = getCountryCodeAndNumber("+" + _value);
      if (ccAndNumber && ccAndNumber.length === 2) {
        setSelectedCountryCode(
          `${String(ccAndNumber[0])?.startsWith("+") ? "" : "+"}${
            ccAndNumber[0]
          }`
        );
        localStorage.setItem(
          "frejun-selected-country-code",
          `${String(ccAndNumber[0])?.startsWith("+") ? "" : "+"}${
            ccAndNumber[0]
          }`
        );
        setCallingValue(ccAndNumber[1]);
      }
    } else {
      setCallingValue(_value);
    }
  };

  // profile icon handlers
  const profileButtonRef = useRef(null);
  const accountSelectRef = useRef(
    reduxSelectedOrg || accountDetails?.data?.profile?.org || null
  );

  // profile dropdown closed on scroll
  function handleProfileCloseOnScroll(e) {
    // reset
    setIsProfileDropdownVisible(false);
    setProfileDropdownAnchorEl(null);
    handleClose(e);
    window.removeEventListener("scroll", handleProfileCloseOnScroll);
  }

  // profile dropdown closed if clicked outside container
  function handleProfileCloseOnClickAway(e) {
    const path = e.composedPath()?.map((pathItem) => {
      let newPathItem = null;
      newPathItem =
        pathItem?.tagName?.toLowerCase() +
        (pathItem?.className ? "." + pathItem?.className : "");
      return newPathItem;
    });

    // clicked outside
    if (!path?.length || path[0]?.includes("div.MuiBackdrop-root")) {
      setIsProfileDropdownVisible(false);
      setProfileDropdownAnchorEl(null);
      window.removeEventListener("click", handleProfileCloseOnClickAway);
    }
  }

  // close profile onScroll and clickAway event listners added
  useEffect(() => {
    if (isProfileDropdownVisible) {
      window.addEventListener("scroll", handleProfileCloseOnScroll);
      window.addEventListener("click", handleProfileCloseOnClickAway);
    }

    return () => {
      window.removeEventListener("scroll", handleProfileCloseOnScroll);
      window.removeEventListener("click", handleProfileCloseOnClickAway);
    };
  }, [isProfileDropdownVisible]);

  // profile icon click handler
  function handleProfileIconClick(e) {
    // if(getComputedStyle(document.querySelector('.top-nav-user-dropdown')).display === 'none') document.querySelector('.top-nav-user-dropdown').style.display = 'block'
    // else document.querySelector('.top-nav-user-dropdown').style.display = 'none'
    // setProfileIsOpen(prevState => !prevState)
    // console.log('currentTarget: ', e.currentTarget)
    // console.log('target: ', e.target)
    e.stopPropagation();
    // setTimeout(()=> {
    let currentStateIsVisible = isProfileDropdownVisible;
    setIsProfileDropdownVisible((prevState) => !prevState);
    setProfileDropdownAnchorEl(currentStateIsVisible ? null : e.currentTarget);
    // }, 500)
  }

  // dialer handleClose
  function handleClose(e) {
    setIsCallingDialerDropdownVisible(false);
    // setCurrentScreenInCallingDialerFlow(0);
    setIsCCDropdownVisible(false);
    setCallingDialerDropdownAnchorEl(null);
    setCallTime({
      session: null,
      //id: null,
      time: { sec: 0, min: 0, hr: 0 },
    });
    if (globalDialerState?.isVisible)
      dispatch(
        setDialer({
          phone_number: "",
          country_code: null,
          isVisible: false,
          contact_list_id: null,
          maskNumbers: false,
        })
      );
  }

  async function handleLogoutClick(e) {
    // @clevertapv1
    pushToClevertap("User logout", true, {
      sessionTime: clevertap.session.getTimeElapsed(),
    });

    // FIXME: check .logout()
    clevertap.logout();
    await deregisterSoftphone();
    const res = await JService.get("/api/v1/auth/logout/?webapp=True");
    console.log("logout res", res);
    navigate("/", { replace: true });
    setTimeout(() => {
      safelyClearLocalStorage();
      setTrialDaysLeftInfo({ trial: false, message: "" });
      setTrialEndInfo({ trial: false, message: "" });
      handleProfileDropdownClose(e);
      dispatch(resetAuthState());
      dispatch(resetAccountDetails());
    }, 300);
  }
  function safelyClearLocalStorage() {
    try {
      const avoid = [
        "length",
        "clear",
        "getItem",
        "key",
        "removeItem",
        "setItem",
        "removeItem",
      ];
      for (const x in localStorage) {
        if (!x || x.includes("WZRK") || avoid.includes(x)) continue;
        localStorage.removeItem(x);
      }
    } catch (error) {
      pushToClevertap(
        "User logout",
        false,
        { sessionTime: clevertap.session.getTimeElapsed() },
        null,
        JSON.stringify(error?.message || error)
      );
      if (localStorage.getItem("frejun-token"))
        localStorage.removeItem("frejun-token");
      if (localStorage.getItem("frejun-sip-data"))
        localStorage.removeItem("frejun-sip-data");
      console.log("cleared auth token in catch block");
    }
  }
  async function checkMicPermissions() {
    if (navigator.mediaDevices === undefined) {
      console.log("navigator.mediaDevices===undefined");
      return Promise.reject(
        new Error("Media devices not available in insecure contexts.")
      );
    }
    // does this mean success ?
    return navigator.mediaDevices.getUserMedia.call(navigator.mediaDevices, {
      audio: true,
      video: false,
    });
  }
  const handleMicPermission = async () => {
    try {
      const permissions = await checkMicPermissions();
      setMicPermissions("granted");
      console.log(permissions);
      setCurrentScreenInCallingDialerFlow(0);
    } catch (err) {
      console.log(err);
      if (err.message?.includes("denied")) setMicPermissions("denied");
      else if (err.message?.includes("dismissed"))
        setMicPermissions("dismissed");
      else setMicPermissions(null);
      console.log(err.message);
      if (browserCalls) {
        setCurrentScreenInCallingDialerFlow(4);
      }
    }
  };
  useEffect(() => {
    navigator.permissions.query({ name: "microphone" }).then((res) => {
      console.log(res.state);
      if (res.state === "granted") {
        setMicPermissions("granted");
      }
    });
  }, []);

  const handleDialerClick = async (e) => {
    if (browserCalls) {
      if (
        !micPermissions ||
        micPermissions === "denied" ||
        micPermissions === "dismissed"
      ) {
        console.log(micPermissions);
        setCurrentScreenInCallingDialerFlow(4);
      }
    }
    setIsCallingDialerDropdownVisible(true);
    setCallingDialerDropdownAnchorEl(e.target || e.currentTarget);
  };

  function setupLocalMedia(arg) {
    try {
      let browserCompatibile = !!(
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia
      );
      if (!browserCompatibile)
        throw new Error(`Your browser does not support sip services`);

      const localMediaElement = document.querySelector("#sip-local-audio");

      let localStream = new MediaStream();
      let senders = arg.sessionDescriptionHandler.peerConnection.getSenders();
      senders.forEach((rec) => {
        if (rec.track && rec.transport) localStream.addTrack(rec.track);
      });
      localMediaElement.srcObject = localStream;
      localMediaElement.onloadedmetadata = (e) => {
        localMediaElement.setAttribute(
          "title",
          "Only for testing, play to recieve own audio feedback"
        );
      };

      console.warn("***<FOR-VISIBILITY>localStream", localStream);
    } catch (error) {
      console.error(error.message);
    }
  }

  const handleCall = async (source) => {
    if (isAccountInactive || isAccountPreActivation) {
      enqueueSnackbar("Subscription is Inactive. Call can not be initiated", {
        variant: "error",
      });
      setIsCallingDialerDropdownVisible(false);
      return;
    }
    if (
      accountDetails?.data?.license === "Viewer" ||
      !accountDetails?.data?.license
    ) {
      enqueueSnackbar("No plan license found. Call can not be initiated", {
        variant: "error",
      });
      setIsCallingDialerDropdownVisible(false);
      return;
    }
    let isValid = false;
    let _value = "",
      _selectedCountryCode = "";
    if (source === "call-btn") {
      isValid = validatePhoneNumber(
        globalDialerState.country_code,
        globalDialerState.phone_number
      );
    } else if (callingValue) {
      isValid = validatePhoneNumber(selectedCountryCode, callingValue);
    }

    if (!isValid) {
      console.log("Invalid phone number");
      enqueueSnackbar("Invalid phone number", { variant: "error" });
      return;
    } else {
      if (source === "call-btn") {
        setContactListId(globalDialerState?.contact_list_id);
        const numberToDial = getCountryCodeAndNumber(
          globalDialerState.country_code
            ? globalDialerState.country_code + globalDialerState.phone_number
            : "" + globalDialerState.phone_number
        );
        setCallingValue(numberToDial[1]);
        setSelectedCountryCode(
          `${String(numberToDial[0])?.startsWith("+") ? "" : "+"}${
            numberToDial[0]
          }`
        );
        _value = `+${numberToDial[0]}${numberToDial[1]}`;
        _selectedCountryCode = `${
          String(numberToDial[0])?.startsWith("+") ? "" : "+"
        }${numberToDial[0]}`;
      } else {
        _value = `${selectedCountryCode}${callingValue}`;
        _selectedCountryCode = selectedCountryCode;
      }
    }
    candidateNumberRef.current = _value;
    if (!browserCalls) setCurrentScreenInCallingDialerFlow(1);

    // calling from virtual number:
    let callingFromVN;

    console.log("virtualNumberState in handle call:", virtualNumberState);

    if (virtualNumberState?.outboundVirtualNumber)
      callingFromVN = virtualNumberState?.outboundVirtualNumber;
    else if (virtualNumberState?.outbound_virtual_numbers?.length === 0) {
      enqueueSnackbar("No virtual number found to initiate the call", {
        variant: "error",
      });
      return;
    } else if (
      virtualNumberState?.outbound_virtual_numbers?.length === 1 &&
      virtualNumberState?.outbound_virtual_numbers?.at(0)?.type === "Calling"
    )
      callingFromVN = outboundVirtualNumbers[0];
    else if (
      virtualNumberState?.outbound_virtual_numbers?.length > 1 &&
      selectedOutboundNumber
    )
      callingFromVN = selectedOutboundNumber;

    if (!callingFromVN) {
      enqueueSnackbar("No virtual number found to initiate the call", {
        variant: "error",
      });
      return;
    }
    if (browserCalls) {
      if (
        Object.keys(callingFromVN)?.includes("number") ||
        Object.keys(callingFromVN)?.includes("country_code")
      ) {
        callingFromVN =
          (callingFromVN.country_code || "+91") + callingFromVN.number;
      }

      // const finalPayload = new URLSearchParams({
      //   virtual_number: callingFromVN,
      //   candidate_number: _value,
      // });
      let payload = new URLSearchParams({ phone_number: _value });

      JService.get(`/api/v1/core/get-contact-details/?${payload}`)
        .then((_res) => {
          if (!_res?.success)
            throw new Error(
              _res?.message || 'Error while fetching "candidate_name"'
            );

          setDialerCandidateDetails({
            name: _res?.data?.name,
            number: _value,
            //id: _res?.data?.call_id + 1,
          });
          setUpdateCallStatusValues({
            tags: [],
            notes: "",
            call_reason: "",
            call_outcome: "",
            name: _res?.data?.name,
            call_id: null,
          });
        })
        .catch((_err) => enqueueSnackbar(_err?.message, { variant: "error" }));
      try {
        const target = UserAgent.makeURI(
          "sip:" + _value + "@call.bistux.com:9080"
        ); // sip:+919829894663@call.bistux.com:9080

        const inviter = new Inviter(sip?.userAgent, target, {
          sessionDescriptionHandlerOptions: {
            constraints: CONTSTRAINTS,
          },
          extraHeaders: sip?.sipToken ? [`token: ${sip.sipToken}`] : [],
        });
        setIsMute(false);
        setIsHold(false);
        // setIsDialpadVisible(false);
        inviter.stateChange.addListener((newState) => {
          try {
            if (!inviter)
              throw new Error(
                `Invalid value for "inviter". Expected of type Invitation, recieved "${inviter?.toString()}" of type "${typeof inviter}"`
              );
            if (!SESSION_STATES.includes(newState))
              throw new Error(
                `Invalid value for "newSessionState". Expected one of ${SESSION_STATES.join(
                  ", "
                )}, recieved "${newState}"`
              );

            setupLocalMedia(inviter); // FIXME: move this inside ?
            switch (newState) {
              case SessionState.Initial:
                console.log(
                  "inviter.incomingInviteRequest ",
                  inviter,
                  newState
                );

                break;
              case SessionState.Establishing:
                console.log(
                  "inviter.incomingInviteRequest estbd",
                  inviter,
                  newState
                ); // ✅
                setSip((prevState) => ({ ...prevState, isCall: true }));

                break;
              case SessionState.Established:
                console.log(
                  "inviter.incomingInviteRequest ",
                  inviter,
                  newState
                );
                let localContact =
                  inviter.outgoingInviteRequest._transaction._request.from;
                let remoteContact =
                  inviter.outgoingInviteRequest._transaction._request.to;
                if (inviteSession?.session === null)
                  setInviteSession({
                    to: remoteContact?.uri?.normal?.user,
                    from: localContact?.uri?.normal?.user,
                    isSet: true,
                    session: inviter,
                  });

                let remoteStream = new MediaStream();
                const remoteMediaElement =
                  document.querySelector("#sip-remote-audio");

                let recs =
                  inviter.sessionDescriptionHandler.peerConnection.getReceivers();

                recs.forEach((rec) => {
                  if (rec.track) remoteStream.addTrack(rec.track);
                });
                remoteMediaElement.srcObject = remoteStream;

                remoteMediaElement.onloadedmetadata = (e) => {
                  remoteMediaElement
                    .play()
                    .then(() => {
                      setCurrentScreenInCallingDialerFlow(2);
                    })
                    .catch((_err) => console.error(_err?.message));
                };

                pushToClevertap(
                  "Call initiated",
                  true,

                  {
                    candidateNumber: _value,
                    virtualNumber: callingFromVN,
                    time: formatTime(Date.now()),
                    source: "dialer (browser-call)",
                  }
                );
                break;
              case SessionState.Terminating:
                console.log(
                  "inviter.incomingInviteRequest ",
                  inviter,
                  newState
                );

              // fall through
              case SessionState.Terminated:
                console.log(
                  "inviter.incomingInviteRequest ",
                  inviter,
                  newState
                );

                setIsMute(false);
                setIsHold(false);
                // setIsDialpadVisible(false);
                setSip((prevState) => ({ ...prevState, isCall: false }));
                setCallingValue("");
                // console.log("calltime id being cleared", callTime.id);
                // clearInterval(callTime.id);
                // setCallTime((prev) => ({
                //   ...prev,
                //   session: null,
                //   // id: null
                // }));
                // setClearTimer(true);  //uncomment if timer is enabled
                setIsOutgoingCall(false);
                setInviteSession({
                  from: "",
                  to: "",
                  session: null,
                  isSet: false,
                });
                break;
              default:
                throw new Error("Unknown session state.");
            }
          } catch (err) {
            console.warn(err);
          } finally {
            // setSip((prevState) => ({ ...prevState, isCall: false }));
            setCallingValue("");
          }
        });
        const inviteOptions = {
          requestDelegate: {
            onAccept: (data) => {
              console.log("inviter req delegate: ", data);
            },
          },
        };
        // user hangup window increased
        setCurrentScreenInCallingDialerFlow(2);
        setSip((prevState) => ({ ...prevState, isCall: true }));
        inviter
          ?.invite(inviteOptions)
          .then(() => {
            // call is ringing
            if (!isOutgoingCall) setIsOutgoingCall(true);

            setCallTime({
              time: { hr: 0, min: 0, sec: 0 },
              session: inviter,
              //id: null,
            });
            // startTimer(); //disabled for now
            setSelectedCountryCode(_selectedCountryCode);
            localStorage.setItem(
              "frejun-selected-country-code",
              _selectedCountryCode
            );
            // enqueueSnackbar(`Call made to ${inviter?.outgoingInviteRequest?._transaction?._request?.to?.uri?.normal?.user}`, { variant: "success"})
          })
          .catch((err) => {
            console.error(err.message);
            setCurrentScreenInCallingDialerFlow(-2);
          });
      } catch (error) {
        console.warn(error.message);
        pushToClevertap(
          "Call initiated",
          false,

          {
            candidateNumber: _value,
            virtualNumber: callingFromVN,
            time: formatTime(Date.now()),
            source: "dialer (browser-call)",
          },

          null,
          error.message
        );
        setCurrentScreenInCallingDialerFlow(-2);
      }
      return;
    }

    const payload = {
      candidate_number: _value,
      virtual_number: callingFromVN.country_code + callingFromVN.number,
      // country_code:
    };
    if (globalDialerState?.contact_list_id) {
      payload["contact_list_id"] = Number(globalDialerState?.contact_list_id);
    }
    JService.post(`/api/v1/calls/initiate-call/`, payload)
      .then((res) => {
        if (!res?.success)
          throw new Error(
            res?.message ||
              `Error occurred while trying to contact ${callingValue}`
          );

        setSelectedCountryCode(_selectedCountryCode);
        localStorage.setItem(
          "frejun-selected-country-code",
          _selectedCountryCode
        );
        setCurrentScreenInCallingDialerFlow(2);
        enqueueSnackbar(res?.message || `Initiated call to ${callingValue}`, {
          variant: "success",
        });

        // set call_id and name of the candidate from response
        // setDialerCandidateDetails(prev =>
        //   ({...prev,
        //     call_id: res?.data?.call_id || res?.data?.event_id || "",
        //     name: res?.data?.candidate_details?.name || ""
        //   }))
        setUpdateCallStatusValues({
          call_id: res?.data?.call_id || res?.data?.event_id || "",
          name: res?.data?.candidate_details?.name || "",
          call_reason: "",
          call_outcome: "",
          notes: "",
          tags: [],
        });

        // reset
        setCallingValue("");
        setDialerCandidateDetails({
          name: res?.data?.candidate_details?.name,
          id: res?.data?.call_id,
          number: res?.data?.candidate_details?.phone_number,
        });
      })
      .catch((err) => {
        enqueueSnackbar(
          err?.message ||
            `Error occurred while trying to contact ${callingValue}`,
          { variant: "error" }
        );

        // closing dialer
        //setIsCallingDialerDropdownVisible(false);
        //setCallingDialerDropdownAnchorEl(null);
        setIsCCDropdownVisible(false);
        // reset
        setCurrentScreenInCallingDialerFlow(-2);
        setCallingValue("");
        setCallTime({
          session: null,
          //id: null,
          time: { sec: 0, min: 0, hr: 0 },
        });
        dispatch(
          setDialer({
            phone_number: "",
            country_code: null,
            isVisible: true,
            contact_list_id: null,
            maskNumbers: globalDialerState.maskNumbers,
          })
        );
        setContactListId(null);
      })
      .finally(() => {
        // @added resets
        setCallingValue("");
        if (globalDialerState?.isVisible || globalDialerState?.phone_number)
          dispatch(
            setDialer({
              phone_number: "",
              country_code: null,
              isVisible: false,
              contact_list_id: null,
              maskNumbers: globalDialerState.maskNumbers,
            })
          );
        setContactListId(null);
      });
  };

  const handleClickInstallChromeExtension = (e) =>
    window.open(
      "https://chrome.google.com/webstore/detail/frejun-chrome-dialler-plu/eninbjdhgaccikhclpomppfcclammnoc",
      "_blank"
    );
  //   const handleUserStatus = () => {
  //     JService.patch("/api/v1/auth/update-userprofile", {
  //       status: userStatus === "Online" ? "AWAY" : "ONLINE",
  //     })
  //       .then((res) => {
  //         if (res?.success) {
  //           setUserStatus(res?.data?.status);
  //           dispatch(saveUserStatus(res?.data?.status));
  //           enqueueSnackbar(res?.message, { variant: "success"})
  //         } else {
  //           throw new Error(res?.message || "Failed to update user status");
  //         }
  //       })
  //       .catch((err) => enqueueSnackbar(err?.message, { variant: "error"}))
  //   };

  const [isProfileIconDisabled, setIsProfileIconDisabled] = useState(true);

  useEffect(() => {
    if (
      (!Array.isArray(allOrgs) || allOrgs?.length === 0) &&
      !isProfileIconDisabled
    )
      setIsProfileIconDisabled(true);
    else if (
      !(!Array.isArray(allOrgs) || allOrgs?.length === 0) &&
      isProfileIconDisabled
    )
      setIsProfileIconDisabled(false);
  }, [allOrgs]);

  const [isAccountSelectDropdownVisible, setIsAccountSelectDropdownVisible] =
    useState(false);

  function handleCloseAccountDropdown(e) {
    setIsAccountSelectDropdownVisible(false);
  }
  const handleChangeSelectedAccount = (e) => {
    // when +Add account is clicked, value is ''
    handleCloseAccountDropdown(e);
    if (!e.target.value) return;
    // @enable to close entire dropdown
    // parent Menu will be closed
    handleProfileDropdownClose(e);
    setTimeout(() => {
      accountSelectRef.current = e.target.value;
      dispatch(saveSelectedOrg(e.target.value));
      setSelectedOrg(e.target.value);
    }, 1);
    // @addTODO: add onRowUpdateChange or onGridTableReady
    // to hide loader-anim only when the ui is ready
  };

  function handleProfileDropdownClose(e) {
    setIsProfileDropdownVisible(false);
    setProfileDropdownAnchorEl(null);
  }
  function handleCallingDialerDropdownClose(e) {
    setIsCallingDialerDropdownVisible(false);
    setCallingDialerDropdownAnchorEl(null);
    // setCurrentScreenInCallingDialerFlow(0);
    setIsCCDropdownVisible(false);
    setCallTime({
      session: null,
      //id: null,
      time: { sec: 0, min: 0, hr: 0 },
    });
  }
  // autoClose handlers // dialer close
  function handleCallingDialerCloseOnScroll(e) {
    // dialer closed
    setIsCallingDialerDropdownVisible(false);
    setCallingDialerDropdownAnchorEl(null);
    setIsCCDropdownVisible(false);

    // reset
    // setCurrentScreenInCallingDialerFlow(0);
    window.removeEventListener("scroll", handleCallingDialerCloseOnScroll);
    setCallingValue("");
    setCallTime({
      session: null,
      //id: null,
      time: { sec: 0, min: 0, hr: 0 },
    });
  }
  // dialer clickAway event handler
  function handleCallingDialerCloseOnClickAway(e) {
    const path = e.composedPath()?.map((pathItem) => {
      let newPathItem = null;
      newPathItem =
        pathItem?.tagName?.toLowerCase() +
        (pathItem?.className ? "." + pathItem?.className : "");
      return newPathItem;
    });
    // clicked outside
    if (!path?.length || path[0]?.includes("div.MuiBackdrop-root")) {
      setIsCallingDialerDropdownVisible(false);
      setIsCCDropdownVisible(false);
      setCallingDialerDropdownAnchorEl(null);
      //   setCurrentScreenInCallingDialerFlow(0);
      setCallTime({
        session: null,
        //id: null,
        time: { sec: 0, min: 0, hr: 0 },
      });
      window.removeEventListener("click", handleCallingDialerCloseOnClickAway);
      setCallingValue("");
    }
  }
  // onScroll close setter
  useEffect(() => {
    // if (
    //   isCallingDialerDropdownVisible
    //   //  ||
    //   // dialerScreen3CallOutcomeOptionsVisible ||
    //   // dialerScreen3CallReasonOptionsVisible ||
    //   // tagsOptionsVisible
    // ) {
    //   window.removeEventListener("scroll", handleCallingDialerCloseOnScroll);
    //   window.removeEventListener("click", handleCallingDialerCloseOnClickAway);
    //   return;
    // }

    if (isCallingDialerDropdownVisible) {
      window.addEventListener("scroll", handleCallingDialerCloseOnScroll);
      window.addEventListener("click", handleCallingDialerCloseOnClickAway);
    }

    return () => {
      window.removeEventListener("scroll", handleCallingDialerCloseOnScroll);
      window.removeEventListener("click", handleCallingDialerCloseOnClickAway);
    };
  }, [
    isCallingDialerDropdownVisible,
    // dialerScreen3CallOutcomeOptionsVisible,
    // dialerScreen3CallReasonOptionsVisible,
    // tagsOptionsVisible,
  ]);
  // -- autoClose handlers end

  function handleCCSearchChange(e) {
    const search = e.target.value;
    setSearchedCountryCode(search);
    // filter from ccData and update ccList
    if (!search || search?.trim()?.length === 0) {
      setCountryCodeList(ccData);
      return;
    }
    const lowercaseSearch = search.toLowerCase();
    const temp = ccData.filter(
      (cc) =>
        `${cc.country.toLowerCase()} +${
          cc.country_code
        } ${cc.iso_code.toLowerCase()}`.includes(lowercaseSearch) ||
        lowercaseSearch.includes(cc.iso_code.toLowerCase())
    );
    setCountryCodeList(temp);
  }

  const getCallFromNumber = () => {
    return (
      selectedOutboundNumber ||
      outboundVirtualNumbers?.find((vns) => vns?.type === "Calling")
    );
  };

  const getCountryFlag = (vn) => {
    let countryName = vn?.location;
    countryName = countryName?.split(", ");
    countryName = countryName?.length > 1 ? countryName?.[1] : countryName?.[0];
    return ccData?.find((elem) => elem.country === countryName)?.image;
  };

  const saveCallDetailsHandler = () => {
    setCurrentScreenInCallingDialerFlow(0);
    setIsCallingDialerDropdownVisible(false);
  };

  return (
    <>
      {/* default visible topNav */}
      <div className="gtop-nav-container regular-font nc-gray-900 t6">
        <div className="top-nav-container">
          <div className="gleft-section">
            <a href="https://frejun.com" className="top-nav-link">
              <img src={topNavLogo} alt="FreJun logo" />
            </a>
          </div>
          <div className="gright-section">
            {/* dialpad icon */}
            {accountDetails?.data?.license === "Plan" && (
              <div
                id="dialpad-icon"
                className="top-nav-link"
                style={{
                  cursor:
                    accountDetails?.data?.license === "Viewer" ||
                    !accountDetails?.data?.license ||
                    isAccountInactive ||
                    isAccountPreActivation ||
                    !canMakeCalls
                      ? "not-allowed"
                      : "pointer",
                }}
                disabled={
                  !accountDetails?.data?.license ||
                  isAccountInactive ||
                  isAccountPreActivation ||
                  !canMakeCalls
                }
                onClick={(e) =>
                  !accountDetails?.data?.license ||
                  accountDetails?.data?.license === "Viewer" ||
                  isAccountInactive ||
                  isAccountPreActivation ||
                  !canMakeCalls
                    ? null
                    : handleDialerClick(e)
                }
              >
                {topNavDialPad("#464d48", "16px", "16px")}
                <span className="t7 regular-font">Dialer</span>
              </div>
            )}

            {/* divider */}
            <div className="top-nav-sep"></div>

            {/* remaining credits container */}
            {canManageBillingSubscription && (
              <>
                <div className="rem-credits-container">
                  <span className="t7 medium-font">Credits:&nbsp;</span>
                  <div className="t7 regular-font">
                    <span style={{ width: "max-content" }}>{`${
                      reduxCredits?.balance < 0 ? "-" : ""
                    }${reduxCredits?.currency === "USD" ? " $" : " ₹"}${
                      reduxCredits?.balance
                        ? Math.abs(
                            Number(reduxCredits?.balance)
                          )?.toLocaleString(
                            reduxCredits?.currency === "INR" ? "en-IN" : "en-US"
                          )
                        : "0"
                    }`}</span>
                    <span
                      style={{
                        cursor:
                          isAccountInactive || isAccountPreActivation
                            ? "not-allowed"
                            : "pointer",
                      }}
                      onClick={
                        isAccountInactive || isAccountPreActivation
                          ? () => null
                          : () =>
                              navigate("/billing", {
                                state: { tab: "Credits" },
                              })
                      }
                    >
                      {topNavAccountPlusIcon()}
                    </span>
                  </div>
                </div>
                <div className="top-nav-sep"></div>
              </>
            )}
            {/* new profile icon */}
            <Box
              sx={{ width: "40px", height: "40px", position: "relative" }}
              onClick={(e) => handleProfileIconClick(e)}
            >
              <Button
                disabled={false}
                hierarchy="white"
                variant="outlined"
                buttonText={
                  accountDetails?.data?.first_name
                    ?.substr(0, 1)
                    ?.toUpperCase() || "-"
                }
                isLoading={
                  accountDetails?.data?.profile?.account_holder
                    ? !Array.isArray(allOrgs)
                    : false
                } //|| allOrgs?.length === 0 because some users have data in retrieve-orgs as [] and callReason and outcome do not get set
                onClick={() => null}
                styleOverride={{
                  ...localStyles.useCaseItemButtonStyle,
                  borderRadius: "50%",
                  height: "40px important",
                  background: colors.nc_gray_100,
                  border: `1px solid ${colors.nc_gray_300}`,

                  "&.MuiLoadingButton-root": {
                    minWidth: "unset",
                    width: "40px",
                    height: "40px",
                  },

                  color:
                    !Array.isArray(allOrgs) || allOrgs?.length === 0
                      ? colors.ic_green_400
                      : "#78807A",

                  "&.Mui-disabled, &.Mui-error": {
                    color: colors.nc_gray_200,
                    background: colors.nc_gray_200,
                    border: `1px solid ${colors.nc_gray_300}`,
                    pointerEvents: "unset",
                    cursor: "not-allowed",
                  },
                  "& .MuiLoadingButton-loadingIndicator": {
                    color: colors.ic_green_400,
                  },
                }}
              />
              <div
                className="status_circle"
                style={{
                  backgroundColor:
                    accountDetails?.data?.profile?.status === "Online"
                      ? "#34CB65"
                      : "#C6CCC8",
                }}
              ></div>
            </Box>
          </div>
        </div>
        {/* for accounts in trial */}
        {trialDaysLeftInfo?.trial && (
          <div className="nav-trial-container">
            {trialDaysLeftInfo?.message}
            {canManageBillingSubscription && (
              <Button
                buttonText="Upgrade"
                hierarchy="green"
                onClick={() => setPlanModalVisible(true)}
                disabled={false}
                styleOverride={{
                  fontSize: "14px",
                  height: "36px",
                  padding: "8px 16px",
                  marginLeft: "16px",
                }}
              />
            )}
          </div>
        )}

        {subsEndInfo?.visible && (
          <div
            className="nav-trial-container"
            style={{ backgroundColor: "#fcf0f0" }}
          >
            <span className="icon">{errorIcon()}</span>
            {subsEndInfo.message}
            {canManageBillingSubscription && (
              <Button
                variant="outlined"
                buttonText={subsEndInfo.btnText}
                hierarchy="white"
                disabled={false}
                onClick={subsEndInfo.btnClick}
                styleOverride={{ backgroundColor: "#fff" }}
              />
            )}
          </div>
        )}
        {licenseDeletionInfo && canManageBillingSubscription && (
          <div
            className="nav-trial-container"
            style={{ backgroundColor: "#fefbe8", position: "relative" }}
          >
            <p className="t7 regular-font">
              {reduxSubscription?.plan_licenses?.deleted > 0
                ? `${reduxSubscription.plan_licenses.deleted} Plan license `
                : ""}
              {reduxSubscription?.plan_licenses?.deleted > 0 &&
                reduxSubscription?.viewer_licenses?.deleted > 0 &&
                "and "}
              {reduxSubscription?.viewer_licenses?.deleted > 0 &&
                `${reduxSubscription?.viewer_licenses?.deleted} Viewer license `}
              set for deletion on{" "}
              <span style={{ textDecoration: "underline" }}>
                {formatDate(reduxSubscription?.end_date)}
              </span>
              . To cancel deletion, contact chat support.
            </p>
            <span
              style={{
                position: "absolute",
                right: "16px",
                cursor: "pointer",
              }}
              onClick={() => setLicenseDeletionInfo(false)}
            >
              {modalCloseIcon()}
            </span>
          </div>
        )}
      </div>

      {/* userProfile -- new */}
      <Menu
        sx={{
          userSelect: "none",
          borderRadius: "6px",
          "& .MuiPaper-root": {
            boxShadow: "0px 8px 8px 2px rgba(95, 102, 97, 0.08)",
            border: "1px solid #E1E6E2",
          },
          "& .MuiList-root": {
            paddingTop: "0px",
            paddingBottom: "0px",
            borderRadius: "6px",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isProfileDropdownVisible}
        disableScrollLock={true}
        anchorEl={profileDropdownAnchorEl}
        onClose={handleProfileDropdownClose}
      >
        {/* details section */}
        <Box
          sx={{
            padding: "20px 24px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "6px 6px 0px 0px",
            background: colors.nc_gray_100,
            borderBottom: `1px solid ${colors.nc_gray_300}`,
          }}
        >
          {/* profile account circle class given to style :after psuedo-element */}
          <div className="profile-account-circle t5 medium-font">
            {accountDetails?.data?.first_name[0]?.toUpperCase() || "-"}
            <div
              className="status_circle"
              style={{
                backgroundColor:
                  accountDetails?.data?.profile?.status === "Online"
                    ? "#34CB65"
                    : "#C6CCC8",
              }}
            ></div>
          </div>

          <Box
            sx={{
              width: "208px",
            }}
          >
            <div className="t6 medium-font nc-gray-900">
              {Object.keys(accountDetails)?.length === 0
                ? "Login to see your name"
                : accountDetails?.data?.first_name +
                  " " +
                  accountDetails?.data?.last_name}
            </div>
            <div
              className="t6 regular-font nc-gray-600"
              style={{ overflow: "hidden", textOverflow: "ellipsis" }}
            >
              {Object.keys(accountDetails)?.length === 0
                ? "-"
                : accountDetails?.data?.email}
            </div>
          </Box>
        </Box>

        {/* primary_account_holder box */}
        {accountDetails?.data?.profile?.account_holder && (
          <Box
            sx={{
              padding: "16px 24px",
              background: colors.nc_gray_100,
              borderBottom: `1px solid ${colors.nc_gray_300}`,
            }}
          >
            <CustomSelect
              value={selectedOrg}
              renderVal={(selected) => {
                if (!selected)
                  return (
                    <p className="regular-font t6 nc-gray-400">
                      Select an account
                    </p>
                  );
                return (
                  <p className="regular-font t6 nc-gray-900">
                    {accountDetails?.allOrgs?.find((ao) => ao?.id === selected)
                      ?.company || "-"}
                  </p>
                );
              }}
              handleChange={handleChangeSelectedAccount}
              options={
                allOrgs &&
                Array.isArray(allOrgs) &&
                allOrgs.map((org) => ({
                  value: org.id,
                  displayValue: org?.company || `"${org?.name}"`,
                }))
              }
              extraOptions={[
                <MenuItem>
                  <CustomTooltip tooltipTitle="Please contact chat support">
                    <p className="t7 regular-font ic-green-400 add-account-option">
                      <span>{topNavAccountPlusIcon(colors.ic_green_400)}</span>
                      Add account
                    </p>
                  </CustomTooltip>
                </MenuItem>,
              ]}
            />
          </Box>
        )}

        {/* other options: set away, vn, extension */}
        <MenuList
          sx={{
            borderRadius: "0 0 6px 6px",

            "& .MuiMenuItem-root": {
              "&:hover": {
                /* Neutral Colors/Grey - 100 */
                background: "#F7FAF8",
              },
              "& .MuiTouchRipple-root": {
                color: colors.ic_green_200,
                // zIndex: -1,
              },
              // '& .Mui-focusVisible': {
              // 	/* Interface Colors/Green - 100 */
              // 	background: colors.ic_green_100,

              // },
              // '& .Mui-selected': {
              // 	/* Interface Colors/Green - 100 */
              // 	background: colors.ic_green_200,
              // },
            },
          }}
        >
          {/* <MenuItem
            sx={{
              ...styles.t6,
              color: colors.nc_gray_900,
              ...styles.regular_font,
              padding: "16px 24px",
              cursor: "pointer",
            }}
            disableGutters={true}
            onClick={() =>
              handleUserStatus(userStatus === "Online" ? "Away" : "Online")
            }
          >
            Set yourself as &nbsp;
            <b>{userStatus === "Online" ? "Away" : "Online"}</b>
          </MenuItem> */}

          <MenuItem
            // selected={false}
            onClick={() => {
              setTimeout(() => {
                navigate("/settings?tab=Calling");
                navigate(0);
              }, 0);
            }}
            disabled={isAccountInactive}
            sx={{
              ...styles.t6,
              color: colors.nc_gray_900,
              ...styles.regular_font,
              padding: "16px 24px",
              cursor: "pointer",
            }}
            disableGutters={true}
            // disableTouchRipple={true}
          >
            Your virtual number
          </MenuItem>

          <MenuItem
            // selected={false}
            onClick={handleClickInstallChromeExtension}
            sx={{
              ...styles.t6,
              color: colors.nc_gray_900,
              ...styles.regular_font,
              padding: "16px 24px",
              cursor: "pointer",
            }}
          >
            Install chrome extension
          </MenuItem>

          {/* divider */}
          <div
            style={{
              height: 1,
              width: "calc(100% + 48px)",
              position: "relative",
              left: "-24px",
              borderTop: "1px solid #E1E6E2",
            }}
          ></div>

          {/* logout */}
          <MenuItem
            onClick={handleLogoutClick}
            selected={false}
            sx={{
              ...styles.t6,
              color: colors.ic_red_500,
              ...styles.regular_font,
              padding: "16px 24px",
              paddingBottom: "20px",
              borderRadius: "0 0 6px 6px",
              cursor: "pointer",
            }}
          >
            Log out
          </MenuItem>
        </MenuList>
      </Menu>
      {/* -- new userProfile end */}

      {/* audio elements hidden from ui for browser calls */}
      {browserCalls && (
        <div style={{ visibility: "hidden", width: "1px", height: "0.1px" }}>
          <audio
            style={{ visibility: "hidden" }}
            controls
            id="sip-local-audio"
          ></audio>
          <audio
            style={{ border: "1px solid green", visibility: "hidden" }}
            autoPlay
            controls
            id="sip-remote-audio"
          ></audio>
        </div>
      )}

      {/* new dialer */}
      <Menu
        sx={{
          // userSelect: 'none',

          borderRadius: "6px",

          "& .MuiPaper-root": {
            boxShadow: "0px 8px 8px 2px rgba(95, 102, 97, 0.08)",
            border: "1px solid #E1E6E2",
          },
          "& .MuiList-root": {
            paddingTop: "0px",
            paddingBottom: "0px",
            // borderRadius: '6px',
            minWidth: "312px",
            width: "312px",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isCallingDialerDropdownVisible}
        disableScrollLock={false}
        anchorEl={callingDialerDropdownAnchorEl}
        onClose={handleCallingDialerDropdownClose}
      >
        {/* first screen for calling dialer */}
        {/* {currentScreenInCallingDialerFlow === 0 && ( */}

        {currentScreenInCallingDialerFlow === -1 && (
          <DialerErrorScreen
            title="No virtual number"
            img={dialerNoVirtualNumber}
            refreshFn={fetchVirtualNumbers}
          />
        )}
        {currentScreenInCallingDialerFlow === -2 && (
          <DialerErrorScreen
            title="Error : Call not initiated"
            img={dialerCallNotInitiated}
            refreshFn={refreshCallNotInitiated}
          />
        )}

        {currentScreenInCallingDialerFlow === -3 && (
          <DialerErrorScreen
            title="No internet"
            img={dialerNoInternet}
            refreshFn={handleBackOnline}
          />
        )}

        {currentScreenInCallingDialerFlow === 4 && (
          <Micpermission
            micPermissions={micPermissions}
            handleMicPermission={handleMicPermission}
          />
        )}

        {currentScreenInCallingDialerFlow === 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
              borderRadius: "6px",
              ...styles.t6,
              ...styles.regular_font,
              background: "#fff",
              width: "312px",
            }}
          >
            <span
              className="t8 medium-font nc-gray-900"
              style={{
                paddingTop: "12px",
                display: "inline-block",
                lineHeight: "auto",
                background: "#f7f7f7",
                textAlign: "center",
                width: "100%",
                paddingBottom: "4px",
              }}
            >
              Calling from
            </span>

            {/* flag and number box */}
            <Box
              sx={{
                background: "#f7f7f7",
                padding: "16px 18px",
                paddingTop: "0px",
                marginBottom: "24px",
                borderBottom: `1px solid ${colors.nc_gray_300}`,
              }}
            >
              {changingVirtualNumber ? (
                <AnimationPage height="40px" />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    flexWrap: "nowrap",
                    background: "#fff",
                    borderRadius: "4px",
                    paddingLeft: "0px",
                    border: `1px solid ${colors.nc_gray_300}`,
                  }}
                >
                  <div
                    style={{
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      padding: "12px",
                      borderRight: `1px solid ${colors.nc_gray_300}`,
                      height: "40px",
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: "4px 0px 0px 4px",
                    }}
                  >
                    <img
                      style={{ width: "24px", height: "16px" }}
                      src={getCountryFlag(getCallFromNumber())}
                    />
                  </div>
                  {/* 0 outbound virtual numbers */}
                  {Array.isArray(outboundVirtualNumbers) &&
                    outboundVirtualNumbers?.length === 0 && (
                      <p style={{ marginLeft: "12px" }}> Contact Frejun</p>
                    )}

                  {/* 1 outbound virtual number */}
                  {Array.isArray(outboundVirtualNumbers) &&
                    outboundVirtualNumbers?.length === 1 && (
                      <p
                        className="t7 regular-font nc-gray-900 outbound-vn-title"
                        title={
                          outboundVirtualNumbers?.at(0)?.name +
                          " " +
                          `${outboundVirtualNumbers
                            ?.at(0)
                            ?.location?.toUpperCase()}`
                        }
                      >
                        <span style={localStyles.spanNoOverFlowStyle}>
                          {" "}
                          {outboundVirtualNumbers?.findIndex(
                            (vns) => vns?.type === "Calling"
                          ) === -1
                            ? outboundVirtualNumbers[0]?.country_code +
                              " " +
                              outboundVirtualNumbers[0]?.number +
                              " .(reminder-call-vn)"
                            : ""}
                          {`${
                            outboundVirtualNumbers[0]?.name
                          } (${outboundVirtualNumbers[0]?.location?.toUpperCase()})`}
                        </span>
                      </p>
                    )}

                  {/* 2 or more outbound virtual numbers */}
                  {Array.isArray(outboundVirtualNumbers) &&
                    outboundVirtualNumbers?.length > 1 && (
                      <CustomSelect
                        value={selectedOutboundNumber || null}
                        handleChange={async (e) => await changeVirtualNumber(e)}
                        selectStyles={{ width: "227px" }}
                        renderVal={(selected) => {
                          console.log("selected ", selected);
                          if (!selected) return <p>Select a virtual number</p>;
                          return (
                            <p
                              style={localStyles.pNoOverflow}
                              className="t7 regular-font nc-gray-900"
                            >
                              {selected?.name} (
                              {capitalizeString(selected?.location)})
                            </p>
                          );
                        }}
                        options={outboundVirtualNumbers?.map((vn) => ({
                          value: vn,
                          displayValue: (
                            <>
                              <div style={{ padding: "16px 12px" }}>
                                <p
                                  style={{ margin: 0 }}
                                  className="t7 regular-font nc-gray-900"
                                >
                                  {vn?.name} ({capitalizeString(vn.location)})
                                </p>
                                <p
                                  style={{ margin: 0 }}
                                  className="t7 regular-font nc-gray-600"
                                >
                                  {vn?.country_code + " " + vn?.number}
                                </p>
                              </div>
                            </>
                          ),
                        }))}
                      />
                    )}
                </Box>
              )}
            </Box>

            {/* input container */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "nowrap",
                marginBottom: "24px",
                position: "relative",
              }}
            >
              <div style={{ display: "flex" }}>
                <div
                  className="dialer--cc_select"
                  style={{
                    border: isCCDropdownVisible
                      ? "1px solid #34cb65"
                      : "1px solid #e1e5e2",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsCCDropdownVisible((prevState) => !prevState);
                  }}
                >
                  <span>{selectedCountryCode}</span>
                  <div className="dialer--dropdown_arrow">
                    <img
                      src={downArrow}
                      alt=""
                      style={{
                        rotate: isCCDropdownVisible ? "180deg" : "0deg",
                      }}
                    />
                  </div>
                </div>

                <input
                  id="dialer-screen-1"
                  style={{
                    border: `1px solid ${colors.nc_gray_400}`,
                    padding: "8px 12px",
                    width: "218px",
                    height: "36px",
                    borderRadius: "0px 4px 4px 0px",
                  }}
                  type="tel"
                  className="regular-font t6 nc-gray-900"
                  placeholder="Enter phone number"
                  value={callingValue}
                  onPaste={handlePastedCallingValue}
                  onChange={(e) => handleCallingValueChange(e)}
                />
              </div>
              {isCCDropdownVisible && (
                <div className="dialer--cc_dropdown_container">
                  <div className="dialer--cc_search">
                    <img src={searchIcon} alt="" />
                    <input
                      type="search"
                      value={searchedCountryCode}
                      onChange={handleCCSearchChange}
                      placeholder="Search"
                      title="Enter country name to search"
                      disabled={!ccData.length}
                    />
                  </div>
                  <ul className="dialer--cc_options custom-scrollbar">
                    {ccData.length > 0 ? (
                      countryCodeList?.map((ccObj) => {
                        return (
                          <li
                            key={ccObj.id}
                            value={ccObj.country_code}
                            onClick={handleCountryCodeSelect(
                              ccObj.country_code
                            )}
                            style={{
                              backgroundColor:
                                selectedCountryCode === ccObj.country_code
                                  ? "#f0fcf4"
                                  : "",
                            }}
                          >
                            <div>
                              <img
                                className="dialer--flag_img"
                                //   @enableenable
                                src={ccObj.image}
                                alt=""
                              />
                              <p
                                className={
                                  selectedCountryCode === ccObj.country
                                    ? "dialer--cc_name"
                                    : ""
                                }
                              >
                                {/* //   @enableenable */}
                                {`${ccObj.country} (${ccObj.country_code})`}
                              </p>
                            </div>
                            {selectedCountryCode === ccObj.country_code && (
                              <span style={{ float: "right" }}>
                                {topNavAccountSelectedAccountTickIcon()}
                              </span>
                            )}
                          </li>
                        );
                      })
                    ) : (
                      <AnimationPage />
                    )}
                  </ul>
                </div>
              )}
            </Box>

            {/* call button container */}
            <Box
              sx={{
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingBottom: "18px",
              }}
            >
              <Button
                passref={callButtonRef}
                disabled={callBtnDisabled}
                onClick={() =>
                  setTimeout(() => handleCall("dialer-call-btn"), 50)
                }
                buttonText="Call"
                hierarchy="green"
                variant="contained"
                isLoading={false}
                icon={{
                  startIcon: initiateOrAcceptCallIcon(
                    callBtnDisabled ? colors.nc_gray_400 : colors.ic_white
                  ),
                  endIcon: null,
                }}
                styleOverride={{
                  width: "100%",
                  marginBottom: "6px",
                  height: "40px",
                  ...styles.b1,
                  ...styles.medium_font,
                  color: colors.ic_white,
                  backgroundColor: callBtnDisabled
                    ? colors.nc_gray_200
                    : colors.ic_green_300,
                  color: callBtnDisabled ? colors.nc_gray_400 : colors.ic_white,
                }}
              />
            </Box>
          </Box>
        )}

        {currentScreenInCallingDialerFlow === 1 && (
          <CallConnecting isBrowserCall={browserCalls} />
        )}
        {currentScreenInCallingDialerFlow === 2 && (
          <CallDetails
            onSaveCallDetails={saveCallDetailsHandler}
            updateCallStatusValues={updateCallStatusValues}
            setUpdateCallStatusValues={setUpdateCallStatusValues}
            browserCalls={browserCalls}
            dialerCandidateDetails={dialerCandidateDetails}
            contactListId={contactListId}
            allTagsRef={allTagsRef}
            sip={sip}
            isMute={isMute}
            isHold={isHold}
            inviteSession={inviteSession}
            handleSipAction={handleSipAction}
            handleHold={handleHold}
            handleMute={handleMute}
            selectedOutboundNumber={selectedOutboundNumber}
            orgDetails={orgDetails}
            outboundVirtualNumbers={outboundVirtualNumbers}
          />
        )}

        {/* incoming call */}
        {currentScreenInCallingDialerFlow === 3 && (
          <Box
            sx={{
              height: "fit-content",
              width: "312px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItem: "center",
              marginRight: 0,
            }}
          >
            {browserCalls && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  borderBottom: "1px solid #e7e7e7",
                  alignItems: "center",
                  padding: "24px",
                  borderRadius: "6px 6px 0px 0px",
                }}
              >
                <img src={dialerAvatar} />
                <div style={{ marginLeft: "16px" }}>
                  {/* ✅ THIS triggered a re-render */}
                  {dialerCandidateDetails?.name && (
                    <p className="t6 nc-gray-900 medium-font">
                      {" "}
                      {dialerCandidateDetails?.name || ""}{" "}
                    </p>
                  )}
                  <p
                    style={{ marginTop: "2px" }}
                    className="t7 regular-font nc-gray-900"
                  >
                    {/* {inviteSession?.from?.includes("+91")
                      ? inviteSession?.from
                      : "+91" + inviteSession?.from} */}
                    {/* {`+${getCountryCodeAndNumber(inviteSession?.from).join(
                      ""
                    )}`} */}
                    {inviteSession.from}
                  </p>
                </div>
              </Box>
            )}

            {/* incoming  call action buttons*/}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  paddingBottom: "16px",
                  paddingTop: "16px",
                  borderTop: `1px solid ${colors.nc_gray_300}`,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  width: "100%",
                }}
              >
                <Button
                  variant="contained"
                  hierarchy="red"
                  isLoading={false}
                  disabled={!inviteSession?.isSet}
                  styleOverride={{
                    flex: 1,
                    marginRight: "10px",

                    width: "135px",
                  }}
                  buttonText={endCallIcon()}
                  onClick={() => handleSipAction("reject")}
                />
                <Button
                  variant="contained"
                  hierarchy="green"
                  isLoading={false}
                  disabled={!inviteSession?.isSet}
                  styleOverride={{
                    flex: 1,

                    width: "135px",
                  }}
                  buttonText={initiateOrAcceptCallIcon()}
                  onClick={() => handleSipAction("accept")}
                />
              </Box>

              {browserCalls ? null : (
                <div className="t7 regular-font nc-gray-900">
                  Please pick up incoming
                  <br /> call on your mobile phone.
                </div>
              )}
            </Box>
          </Box>
        )}
      </Menu>
      {/* -- new dialer end */}

      <PlanModal1
        visible={planModalVisible}
        handleClose={() => setPlanModalVisible(false)}
      />
    </>
  );
}

const localStyles = {
  useCaseItemButtonStyle: {
    padding: "0px",
  },
  spanNoOverFlowStyle: {
    display: "inline-block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
  },
  pNoOverflow: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "calc(100%)",
    margin: 0,
    lineHeight: "24px",
  },
};
