import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import Button from "../design/Button";
import { JService } from "../api-service/ApiService";
import { enqueueSnackbar } from "notistack";
import "../signup/signupscreen7.css";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_LIVE_KEY);
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TEST_KEY);

const CheckoutForm = ({ handleError, handleToggleWidget, isWidgetOpen }) => {
  const API_BASE_URL = process.env.REACT_APP_BETA;
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }

    // Create the SetupIntent and obtain clientSecret
    // const res = await fetch(API_BASE_URL+"/api/v1/subscriptions/setup-intent", {
    //   method: "POST",
    //   headers: {"Content-Type": "application/json"},
    // });
    const url = "/api/v1/subscriptions/setup-intent/";
    const res = await JService.post(API_BASE_URL + url, {});
    // const response = await res.json();

    const clientSecret = res.client_secret;

    // Use the clientSecret and Elements instance to confirm the setup
    const { error } = await stripe.confirmSetup({
      elements,
      clientSecret,
      confirmParams: {
        // return_url: 'https://dev.frejun.com/signup/trial-number/',
        return_url: "https://product.frejun.com/signup/trial-number/",
        //  return_url:'http://localhost:3000/signup/trial-number/'
      },
      redirect: "always",
    });
    setLoading(false);

    if (error) {
      handleError(error);
    }
  };

  const handleOpenChatWidget = () => {
    if (!isWidgetOpen) {
      handleToggleWidget();
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="res"
      style={{
        minHeight: "410px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <PaymentElement
        options={{
          terms: {
            card: "never",
            cashapp: "never",
          },
        }}
      />
      <div
        style={{
          border: "1px solid #34cb65",
          borderRadius: "6px",
          padding: "16px",
          marginTop: "15px",
        }}
      >
        <p className="t6 regular-font fonts7" style={{ marginTop: "0" }}>
          {" "}
          You'll only be charged if you upgrade after the trial. We collect card
          details to ensure genuine sign-ups.
          <br />
        </p>
        <p className="t6 regular-font fonts7" style={{ marginTop: "8px" }}>
          Prefer a trial without card details? Contact&nbsp;
          <span
            style={{
              textDecoration: "underline",
              color: "#2eb258",
              cursor: "pointer",
            }}
            onClick={handleOpenChatWidget}
          >
            chat support.
          </span>
        </p>
      </div>
      <div className="buttons-container" style={{ marginTop: "40px" }}>
        <Button
          className="s7btn"
          hierarchy="green"
          onClick={handleSubmit}
          buttonText="Next"
          disabled={!stripe}
          isLoading={loading}
        />
      </div>
    </form>
  );
};

export default function CardDetails({ handleToggleWidget, isWidgetOpen }) {
  const appearance = {
    theme: "stripe",
    variables: {
      colorPrimary: "#34cb65",
      colorBackground: "#ffffff",
      colorText: "#464d48",
      fontFamily: "Inter , sans-serif",
      borderRadius: "4px",
      fontBaseSize: "12px",
      fontBaseName: "Inter",
    },
  };
  const options = {
    appearance,
    mode: "setup",
    currency: "usd",
  };

  const handleError = (err) => {
    enqueueSnackbar(err?.message || "Something went wrong", {
      variant: "error",
    });
  };

  return (
    <div className="card-details-container">
      <Elements options={options} stripe={stripePromise}>
        <CheckoutForm
          handleError={handleError}
          handleToggleWidget={handleToggleWidget}
          isWidgetOpen={isWidgetOpen}
        />
      </Elements>
    </div>
  );
}
